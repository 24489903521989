import styled from "styled-components";
import imgDarki from '../../assets/images/darkivoreHeroBackground.png';
import imgDarkiHunting from '../../assets/images/HuntingBackground.jpeg';
import imgDigital from '../../assets/images/DigitalBackground.png';
import imgFeature from '../../assets/images/FeatureBackground.png';
import imgCore from '../../assets/images/CoreBackground.png';

export const DarkivoreBackground = styled.nav`
  width: 100%;
  height: 120vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    height: 110vh;
  }
`;

export const DarkivoreImg = styled.div`
  width: 100vw;
  height: 120vh;
  position: absolute;
  background-image: url(${imgDarki});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-position: start;
`;

export const DarkivoreTitle = styled.div`
  color: white;
  height: 115vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: 110vh;
  }
`;

export const DarkivoreLogo = styled.img`
  width: 10vw;
  margin-top: 5vh;
  @media screen and (max-width: 800px) {
    width: 110px;
  }
`;

export const DarkivoreTextTitle = styled.h1`
  font-size: 1.4vw;
  font-weight: 400;
  margin-top: ${props => props.marginTop ?? '5vh'};
  margin-bottom: 4vh;
  padding-inline: 15vw;
  box-sizing: border-box;
  text-align: center;
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 0vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const DarkivoreCoreTextTitle = styled.h2`
  font-size: 1.8vw;
  font-weight: 400;
  margin-top: ${props => props.marginTop ?? '5vh'};
  margin-bottom: 4vh;
  padding-inline: 15vw;
  box-sizing: border-box;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 10vh;
    margin-bottom: 0vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const SwiperDarkiMobile = styled.div`
  position: relative;
  margin-top: 3vh;
  @media screen and (min-width: 801px){
    display: none;
  }
`;

export const ProductDarkiCircle = styled.div`
	position: absolute;
	top: -30px;
	left: 50%;
	width: 70px;
	height: 70px;

	width: 9vh;
	height: 9vh;

	transform: translateX(-50%);
	background-image: url(${(props) => props.img});
	background-repeat: no-repeat;
  	background-size: cover;
	@media screen and (max-width: 1600px) {
		width: 70px;
		height: 70px;
		top: -29%;
	}
	@media screen and (max-width: 800px) {
		width: 73px;
		height: 73px;
		top: -25%;
	}
`;

export const FeatureTextTitle = styled.h2`
  margin-top: 0vh;
  font-size: 1.8vw;
  font-weight: 400;
  margin-bottom: 5vh;
  padding-inline: 15vw;
  box-sizing: border-box;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 30vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const DarkivoreTextBody = styled.div`
  font-size: 1.2vw;
  font-weight: 400;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    text-align: center;
    padding-inline: 15vw;
    margin-top: 3vh;
  }
`;

export const DarkivoreButton = styled.a`
text-decoration: none;
border-radius: 6px;
background-blend-mode: multiply;
/* Drop Shadow Style */
box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
color: var(--black, #000);
text-align: center;
font-weight: 400;
width: ${props => props.width ?? '7vw'};
padding: ${props => props.padding ?? '0.5%'};
font-size: 1vw;
background: white;
color: #1F3B71;
cursor: pointer;
margin-top: 6vh;
font-family: Helvetica;
@media screen and (max-width: 800px) {
    width: auto;
    font-size: 14px;
    padding: 2%;
    padding-inline: 5%;
    margin-top: 6vh;
  }
`

export const HuntingBackground = styled.nav`
  width: 100%;
  height: ${props => props.height ?? '170vh'};
  position: relative;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const HuntingImg = styled.div`
  width: 100%;
  height: 170vh;
  position: absolute;
  background-image: url(${imgDarkiHunting});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  @media screen and (max-width: 800px) {
    height: 100.5%;
  }
`;

export const HuntingTitle = styled.div`
  color: white;
  height: ${props => props.height ?? '170vh'};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const HuntingLogo = styled.img`
  width: 10vw;
  margin-top: 5vh;
`;

export const HuntingeBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: 135vh;
  width: 75vw;
  margin: auto;
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  margin-top: 25vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 800px) {
    height: auto;
    width: 80vw;
    margin-top: 24vh;
    margin-bottom: 10vh;
  }
`;

export const HuntingeCyberBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: 140vh;
  color: #1F3B71;
  width: 75vw;
  margin: auto;
  border-radius: 15px;
  margin-top: 25vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 800px) {
    height: auto;
    width: 80vw;
    margin-top: 20vh;
    margin-bottom: 10vh;
    padding-top: 10vh;
  }
`;

export const HuntingHead = styled.h2`
  color: ${props => props.color ?? 'var(--darkivore-dark-blue, #1F3B71)'};
  text-align: center;
  /* Heading H1 */
  font-size: 1.4vw;
  text-transform: uppercase;
  font-weight: normal;
  margin-top: ${props => props.marginTop ?? '15vh'};
  margin-bottom: ${props => props.marginBottom ?? ''};
  font-family: "HelveticaLt", Arial, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 16vh;
    margin-bottom: 2vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const HuntingCyberHead = styled.h2`
font-weight: normal;
  color: var(--darkivore-dark-blue, #1F3B71);
  text-align: center;
  /* Heading H1 */
  font-size: 1.4vw;
  text-transform: uppercase;
  margin-top: 6vh;
  font-family: "HelveticaLt", Arial, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 5vh;
    padding-inline: 10vw;
    margin-top: 1vh;
  }
`;

export const DigitalHead = styled.h2`
  color: var(--darkivore-dark-blue, #1F3B71);
  text-align: center;
  /* Heading H1 */
  font-size: 1.4vw;
  margin-bottom: 0;
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 5vh;
  font-family: "HelveticaLt", Arial, sans-serif;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 13vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const HuntingText = styled.div`
  color: var(--black, #000);
  width: ${props => props.width ?? '58vw'};
  font-size: 0.95vw;
  text-align: ${props => props.textAlign ?? 'center'};
  margin-top: ${props => props.marginTop ?? ''};
  margin-bottom: ${props => props.marginBottom ?? ''};
  @media screen and (max-width: 800px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.889%;
    font-family: "HelveticaLt", Arial, sans-serif;
    text-align: center;
    margin-bottom: 7vh;
    margin-top: 1vh;
  }
`;

export const ManagedPackageText = styled.div`
  font-size: 0.95vw;
  color: white;
  text-align: center;
  margin-bottom: 5vh;
  font-family: "HelveticaLt", Arial, sans-serif;
  width: 65vw;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.889%;
    text-align: center;
    margin-bottom: 5vh;
    width: 100%;
  }
`;

export const HuntingHeadLogo = styled.img`
  position: absolute;
  width: 330px;
  left: 50%;
  transform: translateX(-50%);
  top: -160px;
  @media screen and (max-width: 1600px) {
    width: 265px;
    left: 50%;
    transform: translateX(-50%);
    top: -128px;
  }
  @media screen and (max-width: 800px) {
    width: 170px;
    top: -83px;
  }
`;

export const ProductDarkivoreDiv = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: start;
	align-content: stretch;
  text-align: center;
  background-color: white;
  color: #002F87;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.25);
  width: ${props => props.isDarki ? '20%' : '40%'};
	height: 12.2vh;
  font-size: 0.8rem;
  padding-inline: ${props => props.isDarki ? '0.5vw' : '0.6vw'};
	padding-top: 1%;
  border-radius: 15px;
  width: 13.2vw;
	@media screen and (max-width: 800px) {
		width: 100%;
		margin-top: 10vh;
		height: 126px;
		/* padding-inline: 0px; */
		box-sizing: border-box;
	}
`;

export const ProductDarkivoreCircle = styled.div`
	position: absolute;
	top: -40px;
	left: 50%;
	width: 10px;
	height: 100px;

	width: 10vh;
	height: 10vh;

	transform: translateX(-50%);
	background-image: url(${(props) => props.img});
	background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 1600px) {
		width: 70px;
		height: 70px;
		top: -35%;
	}
    @media screen and (max-width: 800px) {
		width: 73px;
		height: 73px;
		top: -25%;
	}
`;

export const ProductDarkivoreText = styled.div`
	font-size: 0.85vw;
	/* margin-top: 19%; */
	margin-top: 5vh;
	@media screen and (max-width: 800px) {
		font-size: 14px;
		cursor: pointer;
  	margin-top: 2vh;
    padding-inline: 10%;
	}
`;

export const ProductDarkiDivSwiperMobile = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
    text-align: center;
    color: #002F87;
	width: 100%;
	height: 126px;
    font-size: 0.8rem;
	padding-top: 1%;
    border-radius: 10px;
	margin-top: 5vh;
	background-color: white;
	cursor: pointer;
`;

export const DigitalBackground = styled.nav`
  width: 100%;
  height: 135vh;
  position: relative;
  z-index: ${props => props.zIndex ?? ''};
  @media screen and (max-width: 800px) {
    height: 1575px;
  }
`;

export const DigitalImg = styled.div`
  width: 100%;
  height: 100.5%;
  position: absolute;
  background-image: url(${imgDigital});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 800px) {
    height: 100.5%;
  }
`;

export const DigitalBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: ${props => props.height ?? '120vh'};
  width: 60vw;
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  margin-top: 0vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 800px) {
    margin-top: 22vh;
    height: auto;
    width: 80vw;
  }
`;

export const FeatureBackground = styled.div`
  width: 100%;
  height: ${props => props.height ?? '240vh'};
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  /* z-index: -1; */
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

const HeightDeatureBundle = '200vh';

export const FeatureBundleBackground = styled.div`
  width: 100%;
  height: 185vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: ${props => props.zIndex ?? ''};
  /* z-index: -1; */
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const FeaturelImg = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  background-image: url(${imgFeature});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;

export const DigitalHeadLogo = styled.img`
  position: absolute;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
  top: -100px;

  @media screen and (max-width: 1600px) {
    width: 160px;
    left: 50%;
    transform: translateX(-50%);
    top: -80px;
  }
  @media screen and (max-width: 800px) {
    width: 150px;
  }

`;

export const FeatureTitle = styled.div`
  color: white;
  height: ${props => props.height ?? '240vh'};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const FeatureBundleTitle = styled.div`
  color: white;
  height: ${HeightDeatureBundle};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const FeatureTextBody = styled.div`
  font-size: 1vw;
  font-weight: 400;
  text-align: center;
  color: ${props => props.color ?? ''};
  line-height: ${props => props.lineHeight ?? ''};
  width: 65vw;
  @media screen and (max-width: 800px) {
    color: var(--White, #FFF);
    text-align: center;
    /* Body 01 */
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.889%;
    margin-bottom: 5vh;
    z-index: 1;
  }
`;

export const FeatureTextSubTitle = styled.div`
  font-size: 0.95vw;
  font-weight: 400;
  text-align: center;
  color: #1F3B71;
  width: 65vw;
  @media screen and (max-width: 800px) {
    text-align: center;
    /* Body 01 */
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.889%;
    margin-bottom: 5vh;
    z-index: 1;
  }
`;

export const HuntingeCyberTextBody = styled.div`
  font-size: 0.95vw;
  font-weight: 400;
  text-align: center;
  text-align: start;
  width: 62vw;
  color: #1F3B71;
  margin-top: 1vh;
  @media screen and (max-width: 800px) {
    text-align: center;
    /* Body 01 */
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.889%;
    margin-bottom: 5vh;
    z-index: 1;
  }
`;

export const FeatureCol = styled.div`
  border-radius: 15px;
  background: var(--lightest-grey, #F0F0F0);
  /* Testimonials Box Shadow */
  box-shadow: 12px 12px 15px 2px rgba(0, 0, 0, 0.17);
  /* height: 1370px; */
  height: 180vh;
  width: 15.8vw;
  /* width: 240px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FeatureDarkivore = styled.div`
  border-radius: 15px;
  background: var(--lightest-grey, #F0F0F0);
  /* Testimonials Box Shadow */
  box-shadow: 12px 12px 15px 2px rgba(0, 0, 0, 0.17);
  /* height: 1370px; */
  height: 120vh;
  width: ${props => props.width ?? '15.8vw'};
  /* width: 240px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FeatureColMobile = styled.div`
  border-radius: 15px;
  background: var(--lightest-grey, #F0F0F0);
  /* Testimonials Box Shadow */
  box-shadow: 12px 12px 15px 2px rgba(0, 0, 0, 0.17);
  /* height: 1370px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
`;

export const FeatureCell = styled.div`
  height: ${(props) => props.isService ? '7.957559681697613vh' : '15.915119363395226vh'};
  background: var(--white, #FFF);
  text-align: center;
  display: flex;
  justify-content: ${props => props.justifyContent ?? 'center'};
  align-items: center;
  position: relative;
  padding-left: ${props => props.paddingLeft ?? ''};
  color: ${props => props.color ?? ''};
  border-radius: ${props => props.borderRadius ?? ''};
  font-size: 1.1vw;
  ${(props) => props.isService ? 'box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15)' : 'box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15)'};
  @media screen and (max-width: 800px) {
    box-shadow: none;
    font-size: 16px;
    color: #00ADBB;
    height: auto;
    margin-top: 15px;
  }
`;

export const FeatureDarkivoreCell = styled.h3`
  height: 14.588859416445624vh;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  border-radius: 15px 15px 0px 0px;
  color: #1F3B71;
  background: var(--white, #FFF);
  text-align: center;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1.1vw;
  ${(props) => props.isService ? 'box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15)' : 'box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15)'}
`;

export const FeatureCellDarkivoreSign = styled.div`
  height: 9vh;
  background: var(--white, #FFF);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1.1vw;
  box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
  border-radius: ${props => props.borderRadius ?? ''};
`;

export const FeatureCellDarkivore = styled.div`
  background: var(--white, #FFF);
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  height: 8.5vh;
  padding-left: 3%; 
  font-size: 1.1vw;
  box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
  border-radius: ${props => props.borderRadius ?? ''};
`;

export const FeatureCellMobile = styled.div`
  height: 14.588859416445624vh;
  border-radius: 15px 15px 0px 0px;
  color: #1F3B71;
  background: var(--white, #FFF);
  text-align: center;
  padding-inline: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  ${(props) => props.isService ? 'box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.15)' : 'box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15)'}
  /* margin-bottom: 5vh; */
`;

export const FeatureCellBodyMobile = styled.div`
  height: 15.915119363395226vh;
  /* padding-left: 8%; */
  color: #1F3B71;
  background: var(--white, #FFF);
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding-inline: 5%;
  box-sizing: border-box;
  align-items: center;
  font-size: 1.1vw;
  box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
  margin-top: 4vh;
`;


export const FeatureLogo = styled.img`
  width: 7vw;
  /* position: absolute; */
  @media screen and (max-width: 800px) {
    width: 65.664px;
  }
`;

export const FeatureText = styled.div`
  font-size: 1vw;
  color: ${(props) => props.isService ? '#006072' : '#689922'};
  text-align: start;
`;

export const FeatureDarkivoreText = styled.div`
  font-size: 1vw;
  color: #689922;
  display: flex;
  flex-direction: row;
  margin-left: 0px;
  text-align: start;
  font-family: Arial, Helvetica, sans-serif;
`;

export const FeatureTextMobile = styled.div`
  color: #689922;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: start;
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 156.25%;
`;

export const FeatureCover = styled.div`
  /* display: flex; */
	/* align-items: flex-end; */
  align-self: center;
`;

export const InfoLogo = styled.img`
  width: 12px;
`;

export const FeatureSign = styled.img`
  /* width: 40px; */
  width: 2.1vw;
`;

export const CoreImg = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  background-image: url(${imgCore});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;

export const HuntingHeadImage = styled.img`
  width: 60vw;
  min-height: 28vh;
  z-index: 1;
  @media screen and (max-width: 800px) {
    height: 79px;
    object-fit: cover;
    border-radius: 15px;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
`;

export const ContainerHunting = styled.div`
  display: flex;
  flex-direction: row;
  width: 60vw;
  margin-top: ${props => props.marginTop ?? ''};
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 5vh;
  }
`;

export const SwiperHuntingImage = styled.img`
  width: 23vw;
  z-index: 1;
`;

export const HuntingHeadSwiper = styled.h2`
  color: var(--darkivore-dark-blue, #1F3B71);
  /* Heading H1 */
  font-size: 1.5vw;
  margin-bottom: 0;
  font-weight: normal;
  text-transform: uppercase;
  text-align: start;
  margin-top: 0;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: ${props => props.marginLeft ?? ''};
  @media screen and (max-width: 800px) {
    font-size: 20px;
    font-family: "HelveticaLt", Arial, sans-serif;
    text-align: center;
    margin-top: 5vh;
    padding-inline: 5vw;
  }
`;

export const DarkivoreContainerHuntingBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: start;
`;

export const UlSwiper = styled.ul`
  color: black;
  font-size: 1vw;
  text-align: start;
  width: 35vw;
  margin-top: ${props => props.marginTop ?? ''};
  @media screen and (max-width: 800px) {
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
    padding-left: 5%;
    width: 100%;
  }
`;

export const DigitalHuntingImageDesk = styled.img`
  width: 54vw;
  margin-top: 0vh;
  z-index: 1;
  min-height: 50vh;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const DigitalHuntingImageMob = styled.img`
  width: 60vw;
  margin-top: 5vh;
  margin-bottom: 5vh;
  min-height: 50vh;
  z-index: 1;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const DigitalFooterTitle = styled.div`
  width: 54vw;
  display: flex;
  flex-direction: row;
  font-size: 1vw;
  margin-top: -3vh;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    width: 60vw;
    margin-top: unset;
  }
`;

export const DigitalFooterHeader = styled.div`
  width: 130vw;

  @media screen and (max-width: 800px) {
    width: 60vw;
  }
`;

export const DigitalFooterSubTitle = styled.div`
  color: black;
  font-size: 0.98vw;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const DigitalFooterBody = styled.div`
  color: #1F3B71;
  font-family: Arial, Helvetica, sans-serif;
  @media screen and (max-width: 800px) {
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
    margin-bottom: 3vh;
  }
`;

export const DigitalFooterTitleMobile = styled.div`
  color: var(--Darkivore-Dark-Blue, #1F3B71);

  /* Resp-Sub-title */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3vh;
  text-transform: uppercase;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const FeatureCellsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 7vh;
  justify-content: space-between;
  width: 76vw; 
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const FeatureCellsContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
  justify-content: space-between;
  width: 80vw;
  margin-bottom: 10vh;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const DarkivoreRowAdvantage = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
  justify-content: space-between;
	align-items: center;
	align-content: stretch;
  width: 60vw;
  margin-top: ${props => props.marginTop ?? '5vh'};
	@media screen and (max-width: 800px) {
		display: none;
	}
`;

export const TakeDownImageDesk = styled.img`
  width: 61vw;
  margin-bottom: 8vh;
  z-index: 1;
  min-height: 60vh;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const TakeDownImageMobile = styled.img`
  width: 65vw;
  margin-bottom: 5vh;
  min-height: 70vh;
  z-index: 1;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const FooterDarkivoreBackground = styled.nav`
  width: 100%;
  height: 50vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  font-weight: 100;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const FooterDarkivoreTitle = styled.div`
  color: white;
  height: 50vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  width: 100%;
  background: var(--thidesoft-gradient, linear-gradient(90deg, #689922 25.31%, #005F21 98.23%));
  margin: auto;
  text-align: center;
  @media screen and (max-width: 800px) {
    height: auto;
    flex-direction: column-reverse;
  }
`;

export const DarkivoreHistoryFooter = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  text-align: start;
  width: 65vw;
  font-size: 1vw;
  @media screen and (max-width: 800px) {
    margin-top: 10vh;
    width: 75%;
    padding-bottom: 10vh;
  }
`;

export const DarkivoreFooterImage = styled.img`
  margin-bottom: 3vh;
  width: 20vw;

  @media screen and (max-width: 800px) {
    width: 80%;
    align-self: start;
  }
`;

export const DarkivoreFooterNewLine = styled.div`

  margin-top: 3vh;
  font-family: "HelveticaLt", Arial, sans-serif;

  @media screen and (max-width: 800px) {
    margin-top: 2vh;
    color: var(--White, #FFF);
    /* Body 01 */
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.889%;
  }
`;