import {
    LogoContainer,
    MembersCardBody,
    MembersContainer,
    MembersLogo,
    MembersName,
    MembersPosition,
    ModuleBorderWrap
} from "./MembersCardElements";

import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';
import parse from 'html-react-parser';

const MembersCard = (props) => {
    return <ModuleBorderWrap className={'members-card'} active={props.active}>
    <MembersContainer onClick={props.clickHandler} active={props.active}>
            <LogoContainer>
                <MembersLogo src={props.logo} />
            </LogoContainer>
        <MembersCardBody active={props.active} className={'members-card-body'}>
            <MembersName>
                {props.name}
            </MembersName>
            <MembersPosition>
                {props.position}
            </MembersPosition>
            {props.desc &&
                <MembersPosition>
                    {parse(props.desc)}
                </MembersPosition>
            }
        </MembersCardBody>
        <div style={{
            height: '50px',
            borderTop: '1px solid #CECECE',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center'
        }}>
            <img src={props.active ? arrowUp : arrowDown} alt="React Logo" width={20} style={{marginTop: '0.5vh'}}/>
        </div>
    </MembersContainer>
    </ModuleBorderWrap>
}

export default MembersCard;