import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate,useLocation } from "react-router-dom";
import Home from './pages/home';
import Advisory from './pages/advisory';
import Darkivore from './pages/darkivore';
import ManagedServices from './pages/services';
import {useEffect, useState} from 'react';
import Navbar from './components/Navbar';
import FooterComponent from './components/Footer';
import ContactUs from './pages/contactUs';
import Careers from './pages/careers';
import Academy from './pages/academy';
import Engineering from './pages/engineering';
import Tacivoar from './pages/tacivoar';
import Octivore from './pages/octivore';
import Labs from './pages/labs';
import Resources from './pages/resources';
import AboutUs from './pages/aboutUs';
import './index.css';
import "aos/dist/aos.css";
import {ToastContainer} from "react-toastify";
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/privacyPolicy';
import JobDescription from './pages/jobDescription';
import CookiePolicy from './pages/cookiePolicy';
import AlertDialogSlide from './common/cookieDialoge';
import SiteLoader from "./components/SiteLoader";

const LoadingHandler = ({setLoading}) => {
    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        setTimeout(()=> {
            setLoading(false);
        },1000)
    }, [location]);

    return null;
};
function App() {

    const [isScrolled, setIsScrolled] = useState(false);
    const [isProductactive, setIsProductactive] = useState(false);
    const [isBlack, setIsBlack] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [index, setIndex] = useState(null);
    const [reshowDialog, setReshowDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Router>
            <LoadingHandler setLoading={setLoading} />

            <ToastContainer hideProgressBar position='bottom-right'/>
            <Navbar isScrolled={isScrolled} isProductactive={isProductactive} isBlack={isBlack} isOpen={isOpen}
                    setOpen={setOpen} index={index} setIndex={setIndex}/>
            <AlertDialogSlide reshowDialog={reshowDialog} setReshowDialog={setReshowDialog}/>
            {/* {!isOpen && ( */}
            <>
                <Routes>
                    <Route exact path="/" element={<Home isScrolled={isScrolled} isOpen={isOpen}/>}/>
                    <Route exact path="/advisory" element={<Advisory isScrolled={isScrolled}/>}/>
                    <Route exact path="/darkivore"
                           element={<Darkivore isScrolled={isScrolled} setIsProductactive={setIsProductactive}/>}/>
                    <Route exact path="/managed-services" element={<ManagedServices isScrolled={isScrolled}/>}/>
                    <Route exact path="/contact-us"
                           element={<ContactUs isScrolled={isScrolled} setReshowDialog={setReshowDialog}/>}/>
                    <Route exact path="/careers" element={<Careers isScrolled={isScrolled}/>}/>
                    <Route exact path="/academy" element={<Academy isScrolled={isScrolled}/>}/>
                    <Route exact path="/engineering"
                           element={<Engineering isScrolled={isScrolled} setIsBlack={setIsBlack}/>}/>
                    <Route exact path="/tacivoar"
                           element={<Tacivoar isScrolled={isScrolled} setIsProductactive={setIsProductactive}/>}/>
                    <Route exact path="/octivore"
                           element={<Octivore isScrolled={isScrolled} setIsProductactive={setIsProductactive}
                                              setIsBlack={setIsBlack}/>}/>
                    <Route exact path="/labs" element={<Labs isScrolled={isScrolled}/>}/>
                    <Route exact path="/resources" element={<Resources isScrolled={isScrolled}/>}/>
                    <Route exact path="/about-us" element={<AboutUs isScrolled={isScrolled}/>}/>
                    <Route exact path="/privacy_notice" element={<PrivacyPolicy isScrolled={isScrolled}/>}/>
                    <Route exact path="/cookie_policy" element={<CookiePolicy isScrolled={isScrolled}/>}/>
                    {/*<Route exact path="/job-description" element={<JobDescription isScrolled={isScrolled} />} />*/}
                    <Route exact path="*" element={<Navigate to="/"/>}/>
                </Routes>
                {/* <Navigate to="/" /> */}
                <FooterComponent setReshowDialog={setReshowDialog}/>
            </>
            {/* )} */}
            {loading && <SiteLoader/> }
        </Router>
    );
}

export default App;
