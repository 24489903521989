import styled from "styled-components";

export const PrivacyPolicyBackground = styled.div`
    background-color: white;
    min-height: 100vh;
    padding-top: 23vh;
    padding-inline: 10vh;
    @media screen and (max-width: 800px) {
      height: auto;
      padding-inline: 5vh;
      padding-top: 15vh;
    }
`;

export const PTextHead = styled.div`
    color: var(--Potech-Dark-Blue, #002F87);
    font-family: Helvetica;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 7vh;
    @media screen and (max-width: 800px) {
      font-size: 23px;
    }
`;

export const PrivacyParagrapgh = styled.div`
    margin-bottom: 3vh;
    font-size: 1vw;
    font-family: "HelveticaLt", Arial, sans-serif;
    @media screen and (max-width: 800px) {
        font-size: 15px;
    }
`;

export const PrivacyTitle = styled.div`
    color: var(--Black, #000);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5625rem;
    margin-bottom: 3vh;
    @media screen and (max-width: 800px) {
        font-size: 15px;
    }
`;