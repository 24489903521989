export const ADVISORY_CARD_TYPES = {
    STRATEGY_ACHIEVE: 'strategy_achieve',
    STRATEGY_DATA: 'strategy_data',
    STRATEGY_SCENARIO: 'strategy_scenario',
    STRATEGY_VIRTUAL: 'strategy_virtual',

    DEFENSE_DIRECTORY: 'defense_directory',
    DEFENSE_INFRASTRUCTURE: 'defense_infrastructure',
    DEFENSE_DATABASE: 'defense_database',
    DEFENSE_USER: 'defense_user',

    VIGILANCE_TARGETED: 'vigilance_targeted',
    VIGILANCE_RED: 'vigilance_red',
    VIGILANCE_SOURCE: 'vigilance_source',
    VIGILANCE_RANSOMWARE: 'vigilance_ransomware',

    RESILIENCE_INCIDENT: 'resilience_incident',
    RESILIENCE_DIGITAL: 'resilience_digital',
    RESILIENCE_BUSINESS: 'resilience_business',
    RESILIENCE_CYBER: 'resilience_cyber'
}

export const ENGINEERING_CARD_TYPES = {
    ENG_DATA: 'eng_data',
    ENG_WORKFLOW: 'eng_workflow',
    ENG_ENGINES: 'eng_engines',
    ENG_UI: 'eng_ui',
    ENG_DASHBOARD: 'eng_dashboard',

    AI_LOG: 'ai_log',
    AI_BIG_DATA: 'ai_big_data',
    AI_ADVANCED_DATA: 'ai_advanced_data',

    CONSULT_SECURITY: 'consult_security',
    CONSULT_PERFORMANCE: 'consult_performance',
    CONSULT_CTO: 'consult_cto',
    CONSULT_USER: 'consult_user',
    CONSULT_VIRTUAL: 'consult_virtual',
    CONSULT_DIGITAL: 'consult_digital',

    SOLUTIONS_SMARTMAIL: 'solutions_smartmail',
    SOLUTIONS_EOMP: 'solutions_eomp',
    SOLUTIONS_ERP: 'solutions_erp',
    SOLUTIONS_SECURE: 'solutions_secure'
}

export const ACADEMY_CARD_TYPES = {
    AC_PURPLE: 'ac_purple',
    AC_DIGITAL: 'ac_digital',
    AC_SOC: 'ac_soc',
    AC_COMP: 'ac_comp',
    AC_MACHINE: 'ac_machine',

    CERT_INFO: 'cert_info',
    CERT_CYBER: 'cert_cyber',
    CERT_CONT: 'cert_cont',
    CERT_GOV: 'cert_gov'
}