import styled from "styled-components";
import ResourcesBack from '../../assets/images/ResourcesBack.png';

export const ResourcesBackground = styled.div`
    height: 250vh;
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 800px) {
      height: auto;
    }
`;

export const TextResourcesHeader = styled.h1`
    color: #FFF;
    /* Title-Internal Page */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 22vh;
    margin-top: 30vh;
    @media screen and (max-width: 800px) {
      text-align: center;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      word-wrap: break-word;
      box-sizing: border-box;
      text-align: center;
      padding-inline: 15%;
  }
`;


export const ResourcesImg = styled.div`
  width: 100%;
  height: 100.5%;
  position: absolute;
  background-image: url(${ResourcesBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

export const ResourcesDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    border-radius: 15px;
    height: 165vh;
    width: 76vw;
    background: var(--white, #FFF);
    filter: drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15));
    @media screen and (max-width: 800px) {
      width: 80vw;
      margin-bottom: 5vh;
      height: auto;
    }
`;

export const MediaDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    border-radius: 15px;
    height: 165vh;
    width: 76vw;
    background: var(--white, #FFF);
    margin-top: 5vh;
    filter: drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15));
    @media screen and (max-width: 800px) {
      width: 80vw;
      margin-top: 20vh;
      margin-bottom: 5vh;
      height: auto;
    }
`;

export const ResourcesHeadText = styled.h2`
margin-bottom: 0;
  color: var(--potech-dark-blue, #002F87);
  text-align: center;
  font-family: Helvetica;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  z-index: 1;
  margin-top: 5vh;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: 15vh;
    margin-bottom: 7vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const ResourcesBody = styled.div`
  border-radius: 15px;
  border: 1px solid var(--white, #FFF);
  background: var(--ocean-gradient, linear-gradient(90deg, #00ADBB 0%, #002F87 100%));
  background-blend-mode: multiply;

  /* Testimonials Box Shadow */
  box-shadow: 15px 15px 30px 5px rgba(0, 0, 0, 0.15);
  width: 61vw;
  height: 130vh;
  @media screen and (max-width: 800px) {
    width: 70vw;
    height: auto;
    margin-bottom: 5vh;
  }
`;

export const ResourcesType = styled.div`
  border-radius: 0px 15px 15px 0px;
  background: var(--white, #FFF);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 37vh;
  padding: 2%;
  width: 17.5vw;
  padding-left: 3%;
  box-sizing: border-box;
  @media screen and (max-width: 800px) {
    width: 100%;
    border-radius: unset;
    height: auto;
  }
`;

export const ResourcesResult = styled.div`
  border-radius: 15px 0px 10px 0px;
  background: var(--white, #FFF);
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 105vh;
  width: 42vw;
  padding: 4%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  @media screen and (max-width: 800px) {
    width: 100%;
    border-radius: unset;
    border-radius: 0px 0px 10px 10px;
    /* box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5); */
  }
`;

export const ResourcesTypeText = styled.div`
  color: var(--black, #000);
  /* Title Copy-Internal Page */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: ${props => props.marginBottom ?? '4vh'};
  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 3vh;
    margin-top: 3vh;
    /* text-align: center; */
  }
`;

export const ApplyFilterButton = styled.div`
  border-radius: 5px;
  background: var(--ocean-gradient, linear-gradient(90deg, #00ADBB 0%, #002F87 100%));
  background-blend-mode: multiply;
  color: var(--white, #FFF);
/* Menu Listing */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 4%;
  width: 11vw;
  /* Standard */
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
  @media screen and (max-width: 800px) {
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
`;

export const RadioLabel = styled.h2`
  color: var(--black, #000);
  margin-bottom: 0;
  margin-top: 0;
  /* Body 02 */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 800px) {
    font-size: 13px;
    /* width: 60%; */
  }
`;

export const ResultDivRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

export const ResultDivCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;


export const ResultImg = styled.img`
  width: 240px;
  height: 140px;
  margin-left: 20px;
  @media screen and (max-width: 1600px) {
    min-width: 200px;
    max-width: 200px;
    height: 100px;
    margin-left: 20px;
  }
  @media screen and (max-width: 800px) {
    margin-left: unset;
    margin: auto;
    height: auto;
    width: 100%;
    margin-bottom: 3vh;
  }
`;

export const ResultTypeText = styled.div`
  color: var(--black, #000);

  /* Body 02 */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3vh;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const ResultTypeBox = styled.div`
  color: var(--black, #000);
  width: fit-content;
  padding: 1%;
  /* Body 02 */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3vh;
  border-radius: 5px;
  border: 1px solid var(--black, #000);
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const ResultText = styled.div`
  color: var(--black, #000);

  /* Title Copy-Internal Page */
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4vh;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export const ResultRead = styled.a`
  color: var(--black, #000);
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const ResultDivider = styled.hr`
  width: 100%;
  /* height: 1px; */
  color: black;
  background-color: black;
  margin-top: 5vh;
  margin-bottom: 5vh;
`;

export const ResourcesContianer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 800px) { 
    flex-direction: column;
  }
`;

export const SearchContainer = styled.div`
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 10%;
  width: 95%;
  margin: auto;
  box-sizing: border-box;
  @media screen and (max-width: 800px) {
    height: 15vh;
  }
`;

export const SearchButtonMobile = styled.div`
  cursor: pointer;
  border-radius: 0.375rem;
  background: var(--White, #FFF);
  /* Drop Shadow Style */
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  /* padding: 5%; */
  margin: auto;
  margin-bottom: 5vh;
  width: fit-content;
  padding-inline: 10%;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const SearchText = styled.div`
  background: var(--Ocean-Gradient, linear-gradient(90deg, #00ADBB 0%, #002F87 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 5%;
  padding-bottom: 10%;
`;