import styled from "styled-components";

export const VigilanceContainer = styled.div`
    border-radius: 12px;
    background: var(--white, #FFF);
    /* Drop Shadow Style */
    color: var(--consulting-light-red, #E20D1A);
    text-align: center;
    /* Expandable Sub Title */
    font-size: 1.3vw;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    /* width: 276px; */
    height: ${(props) => props.active ? '30vh' : '26vh'};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
`;

export const ModuleBorderWrap = styled.div`
    position: relative;
    ${(props) => props.isBlack ?
        `background: ${props.active ? 'linear-gradient(to right, rgba(255, 220, 0, 1), rgba(251, 186, 0, 1))' : 'none'};`
        :
        `background: ${props.active ? 'linear-gradient(to right, #E20D1A, #76160E)' : 'none'};`
    }
    padding: ${props => props.active ? '3px' : '0px'};
    border-radius: 15px;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    text-align: center;
    /* Expandable Sub Title */
    font-size: 1.5vw;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    /* width: 276px; */
    width: 13.5vw;
    height: ${(props) => props.active ? '30vh' : '26vh'};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
`;

export const VigilanceContainerText = styled.h3`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.3vw;
    height: 22vh;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-inline: 4%;
`;
export const ModuleAcademyBorderWrap = styled.div`
    position: relative;
    background: ${props => props.active ? 'linear-gradient(to right, #9E3223, #E65300)' : 'none'};
    padding: ${props => props.active ? '3px' : '0px'};
    border-radius: 15px;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    /* Expandable Sub Title */
    font-size: 1.5vw;
    font-style: normal;
    line-height: normal;
    /* width: 276px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* cursor: pointer; */
    width: 59vw;
    height: auto;
    text-align: start;
    text-transform: none;
`;

export const ModuleAcademyBorderWrapBody = styled.div`
    background-color: white;
    width: 59vw;
    border-radius: 12px;
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    color: black;
    box-sizing: border-box;
    padding: 2%;
    padding-inline: 3%;
`;

export const ModuleAcademyBorderWrapBodyText = styled.div`
    color: var(--black, #000);
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 1vw;
`;

export const ModuleEngineeringBorderWrap = styled.div`
    position: relative;
    background: ${props => props.active ? 'linear-gradient(to right, rgba(255, 220, 0, 1), rgba(251, 186, 0, 1))' : 'none'};
    
    padding: ${props => props.active ? '3px' : '0px'};
    border-radius: 15px;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    /* Expandable Sub Title */
    font-size: 1.5vw;
    font-style: normal;
    line-height: normal;
    /* width: 276px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* cursor: pointer; */
    width: 59vw;
    height: auto;
    text-align: start;
    text-transform: none;
`;

export const ModuleVigilanceBorderWrap = styled.div`
    position: relative;

    background: ${props => props.active ? 'linear-gradient(to right, #E20D1A, #76160E)' : 'none'};
    
    padding: ${props => props.active ? '3px' : '0px'};
    border-radius: 15px;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    /* Expandable Sub Title */
    font-size: 1.5vw;
    font-style: normal;
    line-height: normal;
    /* width: 276px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* cursor: pointer; */
    width: 59vw;
    height: auto;
    text-align: start;
    text-transform: none;
`;

export const ModuleVigilanceBorderWrapBody = styled.div`
    background-color: white;
    width: 59vw;
    border-radius: 12px;
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    color: black;
    box-sizing: border-box;
    padding: 2%;
    padding-inline: 3%;
    font-size: 1vw;
`;

export const ModuleVigilanceBorderWrapBodyText = styled.div`
    color: var(--black, #000);
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 1vw;
`;