import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { useState } from 'react';

import linkedin from '../assets/socialMedia/linkedin.svg';
import facebook from '../assets/socialMedia/facebook.svg';
import twitter from '../assets/socialMedia/x.svg';
import youtube from '../assets/socialMedia/youtube.svg';
import media from '../assets/socialMedia/media.svg';

const SocialLogo = styled.img`
    width: 2.93138rem;
    cursor: pointer;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ThanksDialog({ open, setOpen, title, message }) {
    function handleClose() {
        setOpen(false);
    }
    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    color: '#002F87',
                    marginTop: 2,
                    padding: '25px'
                }} >{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description"
                        sx={{
                            color: 'black',
                            textAlign: 'center',
                            fontSize: 15
                        }}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-evenly', margin: 'auto', marginBottom: '35px', width: '35%' }}>
                <a rel='nofollow' href='https://www.linkedin.com/company/potechglobal' target='_blank'><SocialLogo src={linkedin} alt=""/></a>
                    <a rel='nofollow' href='https://www.facebook.com/potechglobal' target='_blank'><SocialLogo src={facebook} alt=""/></a>
                    <a rel='nofollow' href='https://twitter.com/potechglobal' target='_blank'><SocialLogo src={twitter} alt="" /></a>
                    <a rel='nofollow' href='https://www.youtube.com/channel/UCtPL7xbwvdFiWgwEnGz7hgg' target='_blank'><SocialLogo src={youtube} alt="" /></a>
                    <a rel="nofollow" href='https://potechglobal.medium.com' target='_blank'><SocialLogo src={media} alt="" /></a>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
