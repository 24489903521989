import styled from "styled-components";
import corn from '../../assets/logos/cornNav.svg';
import { NavLink as BaseNavLink } from "react-router-dom";
const marginInline = '1px';
const paddingInline = '1.07vw';
export const Nav = styled.nav`
  width: 100%;
  display: flex;
  position: ${(props) => props.isWhite ? "fixed" : "absolute"};
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${(props) => props.isWhite ? "white" : "transparent"};
  align-items: center;
  align-content: stretch;
  color: ${(props) => props.isWhite ? "#565656" : props.isBlack ? 'black' : 'white'};
  z-index: 10;
  padding-bottom: 0.3%;
  ${(props) => props.isWhite ? 'box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);' : ""};
  transition: 0.8s all ease;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const NavMobile = styled.nav`
  width: 100%;
  height: 13vh;
  display: flex;
  position: ${(props) => props.isOpen || props.isScrolled ? 'fixed' : 'absolute'};
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${(props) => props.isOpen || props.isScrolled ? "white" : "transparent"};
  align-items: center;
  align-content: stretch;
  color: ${(props) => props.isOpen || props.isWhite ? "#565656" : 'white'};
  z-index: 10;
  padding-bottom: 0.3%;
  ${(props) => props.isOpen || props.isScrolled ? 'box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);' : ""};
  transition: 0.8s all ease;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const Logo = styled.img`
  height: auto;
  width: ${props => props.isScrolled ? '10vw' : '15vw'};
  padding-left: 5%;
  ${props => props.isOpen ? 'padding: 3vh 0 3vh 0;' : ''};
  cursor: pointer;
  z-index: 2;
  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding-left: 5%;
  }
  @media screen and (max-width: 1033px) and (min-width: 801px) {
    width: 15%;
    padding-left: 2%;
  }
  @media screen and (max-width: 800px) {
    width: 40vw;
    padding-left: 5%;
  }
`;
export const NavsCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: stretch;
  margin-right: 3%;
  z-index: 2;
`

export const NavsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: stretch;
  font-family: Helvetica;
  @media screen and (max-width: 800px) {
      display: none;
  }
`;

export const Navs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: stretch;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9114583333333334vw;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const NavsMobile = styled.div`
  @media screen and (min-width: 801px) {
    display: none;
  }
  z-index: 5;
  padding-right: 5%;
`;

export const SubNavsHeader = styled(BaseNavLink)`
  padding: ${props => props.isScrolled ? '1% 1.5%' : '1.5%'};
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1.05vw;
`;

// product item with its container

export const ContainerProduct = styled.div`
  display: none;
  background-color: #fff;
  color: black;
  position: absolute;
  margin-top: 7vh;
  transform: translateX(-22vw);
  width: 70vw;
  padding: 15%;
  padding-inline: 30%;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  &:after {
    content: "";
    position: absolute;
    top: -2.5vh;
    left: 23.4vw;
    transform: translateX(-0%);
    background-image: url(${corn});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    width: 38px; /* Adjust the width and height of the vector image */
    height: 29px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -5vh;
    left: 23.4vw;
    transform: translateX(-30%);
    width: 50%; /* Adjust the width and height of the vector image */
    height: 100%;
  }

  @media screen and (min-width: 1600px) {
    margin-left: -5vw;
    &:after {
      left: 28.6vw;
    }
    &:before {
      left: 28.6vw;
    }
  }

  @media screen and (max-width: 1300px) {
    transform: translateX(-30vw);
    &:after {
      left: 30.7vw;
    }
    &:before {
      left: 30.7vw;
    }
  }
`;

export const SubNavsProduct = styled.span`
  padding: ${props => props.isScrolled ? '0.5% 1.5%' : '1.5%'};
  font-weight: 700;
  text-align: center;
  position: relative;
  white-space: nowrap;
  margin-inline: ${marginInline};
  padding-inline: ${paddingInline};
  cursor: pointer;
  border-bottom: 5px solid transparent;
  font-family: Helvetica;
  &:hover {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
    ${ContainerProduct} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ${props => props.active ? `border-bottom: 5px solid ${props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};` : ''}
  
  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding: 3%;
    white-space: nowrap;
    font-size: 85%;
  }
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
  z-index: 2;
`;

// advisory item with its container

export const ContainerAdvisory = styled.div`
  display: none;
  background-color: #fff;
  color: black;
  position: absolute;
  margin-top: 7vh;
  transform: translateX(-29.1vw);
  width: 70vw;
  padding: 15%;
  padding-inline: 30%;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  &:after {
    content: "";
    position: absolute;
    top: -2.5vh;
    left: 30.3vw;
    transform: translateX(-0%);
    background-image: url(${corn});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    width: 38px; /* Adjust the width and height of the vector image */
    height: 29px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -5vh;
    left: 30.3vw;
    transform: translateX(-30%);
    width: 50%; /* Adjust the width and height of the vector image */
    height: 100%;
  }
  @media screen and (min-width: 1600px) {
    margin-left: -5.8vw;
    &:after {
      left: 36.25vw;
    }
    &:before {
      left: 36.25vw;
    }
  }
  @media screen and (max-width: 1300px) {
    &:after {
      left: 29.7vw;
    }
    &:before {
      left: 29.7vw;
    }
  }
`;

export const AdvisoryNavLink = styled(BaseNavLink)`
  padding: ${props => props.isScrolled ? '0.5% 1.5%' : '1.5%'};
  font-weight: 700;
  text-align: center;
  padding-inline: ${paddingInline};
  position: relative;
  margin-inline: ${marginInline};
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  border-bottom: 5px solid transparent;
  font-family: Helvetica;
  cursor: pointer;
  &.active {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
  }
  &:hover {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
    ${ContainerAdvisory} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding: 3%;
    white-space: nowrap;
    font-size: 85%;
  }
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
  z-index: 2;
`;

// managed services item with its container

export const ContainerManaged = styled.div`
  display: none;
  background-color: #fff;
  color: black;
  position: absolute;
  margin-top: 7vh;
  transform: translateX(-14vw);
  width: 45vw;
  padding: 15%;
  padding-inline: 30%;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  &:after {
    content: "";
    position: absolute;
    top: -2.5vh;
    left: 17.6vw;
    transform: translateX(-0%);
    background-image: url(${corn});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    width: 38px; /* Adjust the width and height of the vector image */
    height: 29px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -5vh;
    left: 17.6vw;
    transform: translateX(-30%);
    width: 50%; /* Adjust the width and height of the vector image */
    height: 100%;
  }

  @media screen and (min-width: 1600px) {
    margin-left: -7vw;
    &:after {
      left: 24.7vw;
    }
    &:before {
      left: 24.7vw;
    }
  }
  @media screen and (max-width: 1300px) {
    &:after {
      left: 16.3vw;
    }
    &:before {
      left: 16.3vw;
    }
  }
`;

export const ManagedNavLink = styled(BaseNavLink)`
  padding: ${props => props.isScrolled ? '0.5% 1.5%' : '1.5%'};
  margin-inline: ${marginInline};
  font-weight: 700;
  text-align: center;
  padding-inline: ${paddingInline};
  position: relative;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  font-family: Helvetica;
  cursor: pointer;
  border-bottom: 5px solid transparent;
  &.active {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
  }
  &:hover {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
    ${ContainerManaged} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding: 3%;
    white-space: nowrap;
    font-size: 85%;
  }
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
  z-index: 2;
`;

// engineering services item with its container

export const ContainerEng = styled.div`
  display: none;
  background-color: #fff;
  color: black;
  position: absolute;
  margin-top: 7vh;
  transform: translateX(-62.9vw);
  width: 84vw;
  padding: 15%;
  padding-inline: 30%;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  &:after {
    content: "";
    position: absolute;
    top: -2.5vh;
    left: 64.9vw;
    transform: translateX(-0%);
    background-image: url(${corn});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    width: 38px; /* Adjust the width and height of the vector image */
    height: 29px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -5vh;
    left: 64.9vw;
    transform: translateX(-30%);
    width: 30%; /* Adjust the width and height of the vector image */
    height: 100%;
  }

  @media screen and (min-width: 1600px) {
    margin-left: -3vw;
    &:after {
      left: 68vw;
    }
    &:before {
      left: 68vw;
    }
  }

  @media screen and (max-width: 1300px) {
    transform: translateX(-61vw);
    &:after {
      left: 62.5vw;
    }
    &:before {
      left: 62.5vw;
    }
  }
`;

export const EngineeringNavLink = styled(BaseNavLink)`
  padding: ${props => props.isScrolled ? '0.5% 1.5%' : '1.5%'};
  font-weight: 700;
  text-align: center;
  position: relative;
  margin-inline: ${marginInline};
  white-space: nowrap;
  padding-inline: ${paddingInline};
  color: inherit;
  text-decoration: none;
  font-family: Helvetica;
  border-bottom: 5px solid transparent;
  cursor: pointer;
  &.active {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
  }
  &:hover {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
    ${ContainerEng} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding: 3%;
    white-space: nowrap;
    font-size: 85%;
  }
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
  z-index: 2;
`;

// academy services item with its container

export const ContainerAcademy = styled.div`
  display: none;
  background-color: #fff;
  color: black;
  position: absolute;
  margin-top: 7vh;
  transform: translateX(-26.5vw);
  width: 40vw;
  padding: 15%;
  padding-inline: 30%;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  &:after {
    content: "";
    position: absolute;
    top: -2.5vh;
    left: 27.5vw;
    transform: translateX(-0%);
    background-image: url(${corn});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    width: 38px; /* Adjust the width and height of the vector image */
    height: 29px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -5vh;
    left: 27.5vw;
    transform: translateX(-30%);
    width: 30%; /* Adjust the width and height of the vector image */
    height: 100%;
  }
  @media screen and (min-width: 1600px) {
    margin-left: -4vw;
    &:after {
      left: 31.7vw;
    }
    &:before {
      left: 31.7vw;
    }
  }
  @media screen and (max-width: 1300px) {
    transform: translateX(-30vw);
    &:after {
      left: 30.5vw;
    }
    &:before {
      left: 30.5vw;
    }
  }
`;

export const AcademyNavLink = styled(BaseNavLink)`
  padding: ${props => props.isScrolled ? '0.5% 1.5%' : '1.5%'};
  font-weight: 700;
  margin-inline: ${marginInline};
  padding-inline: ${paddingInline};
  text-align: center;
  position: relative;
  white-space: nowrap;
  color: inherit;
  border-bottom: 5px solid transparent;
  text-decoration: none;
  font-family: Helvetica;
  cursor: pointer;
  &.active {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
  }
  &:hover {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
    ${ContainerAcademy} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding: 3%;
    white-space: nowrap;
    font-size: 85%;
  }
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
  z-index: 2;
`;

// resources services item with its container

export const ContainerResources = styled.div`
  display: none;
  background-color: #fff;
  color: black;
  position: absolute;
  margin-top: 7vh;
  transform: translateX(-22.3vw);
  transform: translateX(-8vw);
  width: 22vw;
  width: 8vw;
  padding: 15%;
  padding-inline: 40%;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  &:after {
    content: "";
    position: absolute;
    top: -2.5vh;
    left: 24vw;
    left: 10vw;
    transform: translateX(-0%);
    background-image: url(${corn});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    width: 38px; /* Adjust the width and height of the vector image */
    height: 29px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -5vh;
    left: 24vw;
    left: 10vw;
    transform: translateX(-30%);
    width: 50%; /* Adjust the width and height of the vector image */
    height: 100%;
  }
  @media screen and (max-width: 1050px) {
    &:after {
      left: 23vw;
      left: 9vw;
    }
    &:before {
      left: 23vw;
      left: 9vw;
    }
  }
`;

export const ResourcesNavLink = styled(BaseNavLink)`
  padding: 1.5%;
  padding-inline: ${paddingInline};
  font-weight: 700;
  text-align: center;
  position: relative;
  white-space: nowrap;
  color: inherit;
  margin-inline: ${marginInline};
  border-bottom: 5px solid transparent;
  font-family: Helvetica;
  text-decoration: none;
  cursor: pointer;
  &.active {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
  }
  &:hover {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
    ${ContainerResources} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding: 3%;
    white-space: nowrap;
    font-size: 85%;
  }
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
  z-index: 2;
`;

export const LabsNavLink = styled(BaseNavLink)`
  padding: ${props => props.isScrolled ? '0.5% 1.5%' : '1.5%'};
  font-weight: 700;
  padding-inline: ${paddingInline};
  text-align: center;
  position: relative;
  margin-inline: ${marginInline};
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  border-bottom: 5px solid transparent;
  font-family: Helvetica;
  cursor: pointer;
  &.active {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
  }
  &:hover {
    border-bottom: 5px solid ${props => props.isBlack ? 'black' : props.isHome || props.isScrolled ? '#E88A0D' : 'white'};
  }

  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding: 3%;
    white-space: nowrap;
    font-size: 85%;
  }
  @media screen and (max-width: 1100px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
  z-index: 2;
`;

export const NavPageMobile = styled.div`
  z-index: 3;
  background-color: white;
  position: fixed;
  height: 100%;
  width: 100%;
  padding-top: 17vh;
  overflow-y: auto;
  box-sizing: border-box;
  padding-inline: 5%;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const LearnMoreNavButton = styled(BaseNavLink)`
  background-color: ${props => props.color};
  color: white;
  text-align: center;
  text-decoration: none;
  width: 100px;
  cursor: pointer;
  width: 6vw;
  font-size: 0.8vw;
  margin-left: 10%;
  padding: 0.3vw;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    opacity: 0.7;
  }
  &:active {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
`;

export const SubNavs = styled.div`
  white-space: nowrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px #CECECE solid;
  padding: 4%;
  padding-inline: 0;
`;

export const SubNavsText = styled.div`
  cursor: pointer;
  color: var(--Dark-Grey, #565656);
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const SubNavsTextLight = styled.div`
  color: var(--Dark-Grey, #565656);
  font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
`;
export const ArrowRight = styled.img`
  cursor: pointer;
`;

export const ArrowLeft = styled.img`
  cursor: pointer;
  margin-right: 4%;
`;

export const ProductDivMobile = styled.div`
  border-bottom: 1px #CECECE solid;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 3vh;
  padding-bottom: 3vh;
`;

export const ProductTitle = styled.div`
  color: var(--Potech-Medium-Blue, #0071CE);
  /* Resp-Menu Title */
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  margin-bottom: 3vh;
`;

export const ProductDescription = styled.div`
  font-family: "HelveticaLt", Arial, sans-serif;
  color: var(--Dark-Grey, #565656);
  /* Resp-Menu Details */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

export const ProductImageMobile = styled.img`
  width: 70px;
  margin-right: 20px;
`;

export const LearnMoreNavButtonMobile = styled.div`
  background-color: ${props => props.color};
  color: #FFF;
  text-align: center;
  /* Resp-Button */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1vw;
  padding-inline: 5%;
  cursor: pointer;
  border-radius: 5px;
  width: 83.797px;
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
  &:hover {
    opacity: 0.7;
  }
  &:active {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
`;