import {TrainingTitle} from "../academy/AcademyElements";
import {ChatIcon, ScrollTop} from "../home/HomeElements";
import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import chatIcon from "../../assets/images/chaticon.png";
import {useEffect, useRef, useState} from "react";
import {
    AdvisoryDivBodyItemsMobile,
    AdvisoryDivItemsMobile,
    AdvisoryDivLineItemsMobile,
    Author,
    CornImg,
    CoverDiv,
    CoverIcon,
    CoverIconContainer,
    PotechHistoryFooterAdvisoryImage,
    PotechHistoryFooterNewLine
} from "../advisory/AdvisoryElement";
import engLogo from '../../assets/logos/engLogo.png'
import aiLogo from '../../assets/logos/aiLogo.png'
import consLogo from '../../assets/logos/consLogo.png'
import solLogo from '../../assets/logos/solLogo.png'
import {
    AIBackground,
    ConstBody,
    ConstEyeIcon,
    ConstTitle,
    ConsultingBackground,
    EngBackground,
    EngImg1,
    EngImg2,
    EngImg3,
    EngImg4,
    EngineeringBackground,
    EngineeringBody,
    EngineeringBodyHead,
    EngineeringBodyMobile,
    EngineeringBodyMobileImg,
    EngineeringButton,
    EngineeringCardContainer,
    EngineeringCover,
    EngineeringDivHeaderItemsMobile,
    EngineeringEyeIcon,
    EngineeringImage,
    EngineeringTextBody,
    EngineeringTextTitle,
    EngineeringTitle,
    FooterEngineeringBackground,
    FooterEngineeringTitle,
    ImageEngineeringItemsMobile,
    ModuleEngineeringBorderWrapBody,
    ModuleEngineeringBorderWrapBodyText,
    PotechHistoryFooterEngineering,
    PotechQuoteFooterEngineering,
    SolutionsBackground
} from "./EngineeringElements";
import {ModuleEngineeringBorderWrap} from "../../components/VigilanceCard/VigilanceCardElements";

import EngCricleLogo from '../../assets/logos/EngCircleLogo.png';
import EngineeringCard from "../../components/EngineeringCard";
import whitePuzzle from '../../assets/logos/whitePuzzle.png';

import aiWhite from '../../assets/logos/aiWhite.png'
import consWhite from '../../assets/logos/consWhite.png'
import obSoftBlack from '../../assets/logos/Obsoft Black Logo.svg'
import FooterDivider from "../../components/FooterDivider";
import {useDispatch, useSelector} from "react-redux";
import {resetTargetSection, setTargetSection} from "../../redux/scrollSlice";
import EngineeringCardMobile from "../../components/EngineeringCardMobile";
import {useMediaQuery} from "react-responsive";

import eng1 from '../../assets/images/engineeringImage.jpg';
import eng2 from '../../assets/images/aiImage.jpg';
import eng3 from '../../assets/images/consultingImage.jpg';
import eng4 from '../../assets/images/solutionImage1.jpg';
import {ENGINEERING_CARD_TYPES} from "../../common/constant";
import {Loader, WrapperDiv} from "../../components/loader";
import {Helmet} from "react-helmet";

const Engineering = ({ isScrolled, setIsBlack }) => {

    const engineeringRef = useRef(null);
    const aiRef = useRef(null);
    const consultRef = useRef(null);
    const solutionRef = useRef(null);

    const [engIndex, setEngIndex] = useState(null);
    const [engineeringText, setEngineeringText] = useState(null);

    const [aiIndex, setAiIndex] = useState(null);
    const [aiText, setAiText] = useState(null);

    const [consIndex, setConsIndex] = useState(null);
    const [consText, setConsText] = useState(null);

    const [solIndex, setSolIndex] = useState(null);
    const [solText, setSolText] = useState(null);

    const targetSection = useSelector((state) => state.scroll.targetSection);
    const dispatch = useDispatch();

    const targetCard = useSelector((state) => state.scroll.targetCard);
    // eng Texts
    const EngDataText = <div>
        Our engineering team is notably specialized in developing no-code data flow solutions.
        <AdvisoryDivLineItemsMobile />
        This means that we create intuitive platforms and tools that allow users to design and manage the flow of data within their systems without the need for extensive coding knowledge.
        <AdvisoryDivLineItemsMobile />
        With our no-code data flows, you can easily connect, transform, and integrate various data sources. This definitely empowers your organization to aggregate information and make efficient data-driven decisions.
    </div>

    const EngWorkText = <div>
        We enable users to automate complex business processes without writing extensive code.
        <AdvisoryDivLineItemsMobile />
        By leveraging our expertise, you can create custom workflows using visual interfaces, connecting various tasks, and orchestrating them seamlessly.
        <AdvisoryDivLineItemsMobile />
        With our no-code workflow builds, you can enhance productivity, reduce manual effort, and improve overall operational efficiency.
    </div>

    const EngEngines = <div>
        Our expert team shines through developing high-performance engines using languages such as C, Java, and Python. We specialize in building robust and efficient software components that form the backbone of clients’ applications and systems.
        <AdvisoryDivLineItemsMobile />
        We design and implement optimized algorithms, data structures, and architectural solutions, making sure your software delivers superior performance and meets demanding computational requirements.
    </div>

    const EngUi = <div>
        We dedicate our passion and attention to creating interactive and user-friendly platforms with exceptional user experience (UX) and user interface (UI) design.
        <AdvisoryDivLineItemsMobile />
        Considering the importance of intuitive front-end interfaces for engaging good interaction through a seamless user experience, we craft visually appealing, responsive, and interactive interfaces that are sure to drive engagement.
    </div>

    const EngDashboard = <div>
        Our engineering team specializes in designing and implementing real-time monitoring dashboards that provide valuable insights into the performance and health of clients’ systems and applications.
        <AdvisoryDivLineItemsMobile />
        Our comprehensive dashboards allow you to visualize critical metrics, data streams, and alerts in real-time.
        <AdvisoryDivLineItemsMobile />
        This leads you to proactively identify any existing issues, track key performance indicators, and make informed decisions that optimize your operations.
    </div>

    // ai Texts
    const aiLog = <div>
        Gain a competitive edge by unlocking the hidden insights within your system logs.
        <AdvisoryDivLineItemsMobile />
        Our AI-powered log pattern analysis empowers you to understand in depth the behavior of your systems.
        <AdvisoryDivLineItemsMobile />
        By identifying patterns, anomalies, and trends, you can proactively address potential issues, optimize performance, and consequently ensure uninterrupted operations and customer satisfaction.
    </div>

    const aiBigData = <div>
        Clients’ vast data resources are actually goldmines of actionable insights.
        <AdvisoryDivLineItemsMobile />
        With our AI-driven big data analytics solutions, you will unleash the full potential of your data by leveraging advanced algorithms and expert analysis.
        <AdvisoryDivLineItemsMobile />
        You will uncover hidden patterns, correlations, and trends that hold the key to operational excellence and strategic decision-making. From optimizing processes to personalizing customer experiences, our solutions empower you to transform data into higher competitive advantages.
    </div>

    const aiAdvancedData = <div>
        These intelligent agents allow clients to stay at the forefront of information gathering.
        <AdvisoryDivLineItemsMobile />
        Our advanced data crawlers and parsers efficiently collect data from various online sources, transform and aggregate it, giving you a comprehensive view of your industry, market trends, and competitors.
        <AdvisoryDivLineItemsMobile />
        With accurate and up-to-date data at your fingertips, you can make informed business decisions, identify new opportunities, and stay ahead of the curve.
    </div>

    // consulting Texts
    const consSec = <div>
        We integrate security measures into every aspect of clients’ systems and processes design. Through secure coding, secure code architecture and other design consulting services, we embed security in the business operations.
        <AdvisoryDivLineItemsMobile />
        We set you up for a proactive approach to cybersecurity threats, fortifying your business from the ground up.
        <AdvisoryDivLineItemsMobile />
        We help you mitigate risks, protect sensitive data, and build trust with your customers.
    </div>

    const consPerform = <div>
        We unlock the full potential of clients’ systems and maximize efficiency with our Performance Assessment consulting services.
        <AdvisoryDivLineItemsMobile />
        Our experts delve into the heart of your infrastructure, applications, and processes, meticulously analyzing performance metrics.
        <AdvisoryDivLineItemsMobile />
        By identifying bottlenecks, optimizing resource utilization, and fine-tuning performance, we help enhancing your productivity and streamlining your operations.
    </div>

    const consCto = <div>
        This allows you access to top-tier technology expertise without the overhead of a full-time executive.
        <AdvisoryDivLineItemsMobile />
        Provided by our seasoned Chief Technology Officers, our CTO as a Service consulting offers strategic guidance and leadership.
        <AdvisoryDivLineItemsMobile />
        Whether you need assistance with technology roadmaps, team management, or innovation strategies, you will benefit from our tailored advice and effective technology adoption, providing you with a competitive edge in your industry.
    </div>

    const consUser = <div>
        To ensure the quality and usability of your software, we employ industry best practices and user-centered approaches to validate clients’ applications from the end-user perspective.
        <AdvisoryDivLineItemsMobile />
        Our comprehensive testing methodologies identify potential usability issues, functional gaps, and performance bottlenecks.
        <AdvisoryDivLineItemsMobile />
        This aims at ensuring a seamless user experience that exceeds customer expectations and drives customer satisfaction.
    </div>

    const consVirtual = <div>
        Our experienced Scrum Masters provide clients with remote guidance, streamlining projects management, facilitating effective collaboration, and ensuring projects success.
        <AdvisoryDivLineItemsMobile />
        With high expertise in agile practices, they drive the adoption of Scrum principles, optimize team workflows, and empower cross-functional teams to deliver high-quality results, even in distributed work environments.
    </div>

    const consDigital = <div>
        We guide clients through every step of their digital journey, starting with the formulation of their strategy and all the way to implementation.
        <AdvisoryDivLineItemsMobile />
        Our experts help you harness emerging technologies, optimize processes to increase operational efficiency and capitalize on new market opportunities.
        <AdvisoryDivLineItemsMobile />
        Accelerating your growth and improving your customer experience, we guide you towards a digitally empowered organization.
    </div>

    // solution Texts
    const solSmart = <div>
        Our advanced mass mailing solution SmartMail is designed to allow clients to reach their target audience effectively and efficiently through technical, personalized and engaging email communications.
        <AdvisoryDivLineItemsMobile />
        Including automated email workflows and detailed analytics, SmartMail optimizes your email campaigns and workflows, driving customer engagement and achieving higher conversion rates.
    </div>

    const solEomp = <div>
        Designed specifically for electoral management bodies and campaigns, EOMP revolutionizes electoral processes.
        <AdvisoryDivLineItemsMobile />
        Our platform streamlines the entire electoral lifecycle, from voter registration, voting statistics, to electoral day voters management and results reporting.
        <AdvisoryDivLineItemsMobile />
        It offers comprehensive features such as voter database management, ballot generation, secure result aggregation, and real-time analytics.
        <AdvisoryDivLineItemsMobile />
        EOMP is the digital transformation companion for a winning electoral campaign.
    </div>

    const solErp = <div>
        We seamlessly integrate industry-leading Enterprise Resource Planning (ERP) and Customer Relationship Management (CRM) systems, empowering our clients to thrive in the digital era.
        <AdvisoryDivLineItemsMobile />
        With our expert services, you streamline your operations, enhance collaboration and gain a 360-degree view of your customers to improve decision-making and customer satisfaction.
    </div>

    const solSec = <div>
        Whether clients need Software-as-a-Service (SaaS), Platform-as-a-Service (PaaS), or Infrastructure-as-a-Service (IaaS), our private hosting infrastructure offers advanced security measures, dedicated resources, and flexible scalability.
        <AdvisoryDivLineItemsMobile />
        Ensuring the highest level of security and reliability, we help you protect your valuable data, maintain regulatory compliance, and experience enhanced performance, all within a secure and managed environment.
        <AdvisoryDivLineItemsMobile />
        You will focus on your core business while we take care of your hosting needs.
    </div>

    useEffect(() => {
        let sectionRef;

        switch (targetSection) {
            case 'engineering':
                sectionRef = engineeringRef;
                break;
            case 'ai':
                sectionRef = aiRef;
                break;
            case 'consult':
                sectionRef = consultRef;
                break;
            case 'solution':
                sectionRef = solutionRef;
                break;
            default:
                sectionRef = null;
                break;
        }

        if (sectionRef && sectionRef.current) {
            const yOffset = -100;
            const y = sectionRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            dispatch(resetTargetSection());
        }
    }, [targetSection, dispatch]);

    useEffect(() => {

        switch (targetCard) {
            // strategy cards
            case ENGINEERING_CARD_TYPES.ENG_DATA:
                setEngIndex(1);
                setEngineeringText(EngDataText);
                break;

            case ENGINEERING_CARD_TYPES.ENG_WORKFLOW:
                setEngIndex(2);
                setEngineeringText(EngWorkText);
                break;

            case ENGINEERING_CARD_TYPES.ENG_ENGINES:
                setEngIndex(3);
                setEngineeringText(EngEngines);
                break;

            case ENGINEERING_CARD_TYPES.ENG_UI:
                setEngIndex(4);
                setEngineeringText(EngUi);
                break;

            case ENGINEERING_CARD_TYPES.ENG_DASHBOARD:
                setEngIndex(5);
                setEngineeringText(EngDashboard);
                break;

            // ai cards
            case ENGINEERING_CARD_TYPES.AI_LOG:
                setAiIndex(1);
                setAiText(aiLog);
                break;

            case ENGINEERING_CARD_TYPES.AI_BIG_DATA:
                setAiIndex(2);
                setAiIndex(aiBigData);
                break;

            case ENGINEERING_CARD_TYPES.AI_ADVANCED_DATA:
                setAiIndex(3);
                setAiIndex(aiAdvancedData);
                break;

            // consulating cards
            case ENGINEERING_CARD_TYPES.CONSULT_SECURITY:
                setConsIndex(1);
                setConsText(consSec);
                break;


            case ENGINEERING_CARD_TYPES.CONSULT_PERFORMANCE:
                setConsIndex(2);
                setConsText(consPerform);
                break;

            case ENGINEERING_CARD_TYPES.CONSULT_CTO:
                setConsIndex(3);
                setConsText(consCto);
                break;

            case ENGINEERING_CARD_TYPES.CONSULT_USER:
                setConsIndex(4);
                setConsText(consUser);
                break;

            case ENGINEERING_CARD_TYPES.CONSULT_VIRTUAL:
                setConsIndex(5);
                setConsText(consVirtual);
                break;

            case ENGINEERING_CARD_TYPES.CONSULT_DIGITAL:
                setConsIndex(6);
                setConsText(consDigital);
                break;

            // solutions
            case ENGINEERING_CARD_TYPES.SOLUTIONS_SMARTMAIL:
                setSolIndex(1);
                setSolText(solSmart);
                break;

            case ENGINEERING_CARD_TYPES.SOLUTIONS_EOMP:
                setSolIndex(2);
                setSolText(solEomp);
                break;

            case ENGINEERING_CARD_TYPES.SOLUTIONS_ERP:
                setSolIndex(3);
                setSolText(solErp);
                break;

            case ENGINEERING_CARD_TYPES.SOLUTIONS_SECURE:
                setSolIndex(4);
                setSolText(solSec);
                break;
            default:
                break;
        }

        if (targetCard) {
            dispatch(resetTargetSection());
        }
    }, [targetCard, dispatch]);
    useEffect(() => {
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
        setIsBlack(true);
        return () => {
            setIsBlack(false);
        };
    }, [setIsBlack])

    const EngineeringItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageEngineeringItemsMobile src={eng1} />
        </WrapperDiv>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                No-Code Data Flows
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {EngDataText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>

        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                No-Code Workflow Builds
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {EngWorkText}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                High-Performance Engines: C, Java, Python
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {EngEngines}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Interactive UX/UI Platforms
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {EngUi}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Real-Time Monitoring Dashboards
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {EngDashboard}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const AIItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageEngineeringItemsMobile src={eng2} />
        </WrapperDiv>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Log Pattern Analysis
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {aiLog}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Big Data Analytics & Actionable Insights
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {aiBigData}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Advanced Data Crawlers & Smart Parsing
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {aiAdvancedData}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const ConsultingItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageEngineeringItemsMobile src={eng3} />
        </WrapperDiv>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Security by Design
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {consSec}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Performance Assessment
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {consPerform}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                CTO as a Service
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {consCto}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                User Acceptance Tests
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {consUser}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Virtual Scrum Master
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {consVirtual}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Digital Transformation
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {consDigital}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const SolutionsItems = <>
        <WrapperDiv>
            <Loader marginTop="12vh" />
            <ImageEngineeringItemsMobile src={eng4} />
        </WrapperDiv>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                SmartMail - Mass Mailing
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {solSmart}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                EOMP - Electoral Operation Management Platform
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {solEomp}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                ERP/CRM Implementation
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {solErp}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
        <AdvisoryDivItemsMobile>
            <EngineeringDivHeaderItemsMobile>
                Secure Private Hosting - SaaS/PaaS/IaaS
            </EngineeringDivHeaderItemsMobile>
            <AdvisoryDivBodyItemsMobile>
                {solSec}
            </AdvisoryDivBodyItemsMobile>
        </AdvisoryDivItemsMobile>
    </>

    const isMobile = useMediaQuery({ query: `(max-width: 800px)` });

    return <>
        <Helmet>
            <title>Software Engineering: Security, quality and high performance</title>
            <meta name="description" content="Solutions ranging from IT and IoT projects to web applications with emphasis on security, quality and high performance." />
        </Helmet>
        <EngBackground>
            <EngineeringTitle>
                <EngineeringTextTitle>SOFTWARE ENGINEERING</EngineeringTextTitle>
                <EngineeringTextBody>
                    Online solutions are today at the heart of any business.
                </EngineeringTextBody>
                <EngineeringTextBody>
                    Within a dynamic Digital Transformation context, our engineering services leverage cutting-edge technologies, offering clients solid platforms to rapidly adapt to internal or external changes and needs.
                </EngineeringTextBody>
                <EngineeringTextBody>
                    Our products and solutions range from IT and IoT projects to web applications with emphasis on security, quality and high performance.
                </EngineeringTextBody>
                <EngineeringButton onClick={() => { engineeringRef.current.scrollIntoView({ behavior: 'smooth' }) }}>DISCOVER</EngineeringButton>
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                <ChatIcon src={chatIcon} />
            </EngineeringTitle>
        </EngBackground>
        <EngineeringCover>
            <CornImg>
                <svg width="5vw" height="64" viewBox="0 0 95 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M93.2442 59.4236C92.3635 60.5719 91.0927 61.5253 89.5607 62.187C88.0286 62.8487 86.29 63.1951 84.5213 63.191H10.7127C8.94399 63.196 7.20501 62.8505 5.67236 62.1895C4.13972 61.5286 2.86799 60.5758 1.98633 59.4279C1.10466 58.2799 0.644456 56.9778 0.652446 55.6537C0.660436 54.3295 1.13634 53.0306 2.03182 51.8887L38.8942 4.16745C39.7767 3.01972 41.0476 2.06639 42.579 1.40351C44.1104 0.740626 45.8481 0.391602 47.617 0.391602C49.386 0.391602 51.1237 0.740626 52.655 1.40351C54.1864 2.06639 55.4574 3.01972 56.3399 4.16745L93.2022 51.8887C94.1045 53.0281 94.584 54.3272 94.5914 55.6518C94.5988 56.9765 94.1337 58.2785 93.2442 59.4236Z" fill="url(#paint0_linear_51_664)" style={{ mixBlendMode: 'normal' }} />
                    <defs>
                        <linearGradient id="paint0_linear_51_664" x1="0.652344" y1="31.7913" x2="94.5915" y2="31.7913" gradientUnits="userSpaceOnUse">
                            <stop offset="0.253125" stopColor="#d1d1d1" />
                            <stop offset="0.711458" stopColor="#cdcdcd" />
                        </linearGradient>
                    </defs>
                </svg>
            </CornImg>
            <AdvisoryDivLineItemsMobile marginTop={'-1vh'} />
            <CoverIconContainer>
                <CoverDiv onClick={() => { dispatch(setTargetSection('engineering')) }}>
                    <CoverIcon src={engLogo} />
                    <div>Engineering</div>
                </CoverDiv>
                <CoverDiv onClick={() => { dispatch(setTargetSection('ai')) }}>
                    <CoverIcon src={aiLogo} />
                    <div>Ai & Machine < br /> Learning</div>
                </CoverDiv>
                <CoverDiv onClick={() => { dispatch(setTargetSection('consult')) }}>
                    <CoverIcon src={consLogo} />
                    <div>Consulting</div>
                </CoverDiv>
                <CoverDiv onClick={() => { dispatch(setTargetSection('solution')) }}>
                    <CoverIcon src={solLogo} />
                    <div>Solutions</div>
                </CoverDiv>
            </CoverIconContainer>
        </EngineeringCover>
        {/* engineering */}
        <EngineeringBackground ref={isMobile ? undefined : engineeringRef}>
            <EngImg1 />
            <TrainingTitle cardIndex={engineeringText}>
                <EngineeringEyeIcon src={EngCricleLogo} />
                <EngineeringBody cardIndex={engineeringText}>
                    <EngineeringBodyHead>
                        Engineering
                    </EngineeringBodyHead>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <EngineeringImage width={'59vw'} src={eng1} />
                    </WrapperDiv>
                    <EngineeringCardContainer>
                        <EngineeringCard width={'11vw'} text={"No-Code Data Flows"} clickHandler={() => {
                            if (engIndex === 1) {
                                setEngIndex(null);
                                setEngineeringText(null);
                            }
                            else {
                                setEngIndex(1);
                                setEngineeringText(EngDataText)
                            }
                        }} active={engIndex === 1} />
                        <EngineeringCard width={'11vw'} text={"No-Code Workflow Builds"} clickHandler={() => {
                            if (engIndex === 2) {
                                setEngIndex(null);
                                setEngineeringText(null);
                            }
                            else {
                                setEngIndex(2);
                                setEngineeringText(EngWorkText)
                            }
                        }} active={engIndex === 2} />
                        <EngineeringCard width={'11vw'} text={"High-Performance Engines: C, Java, Python"} clickHandler={() => {
                            if (engIndex === 3) {
                                setEngIndex(null);
                                setEngineeringText(null);
                            }
                            else {
                                setEngIndex(3);
                                setEngineeringText(EngEngines)
                            }
                        }} active={engIndex === 3} />
                        <EngineeringCard width={'11vw'} text={"Interactive UX/UI Platforms"} clickHandler={() => {
                            if (engIndex === 4) {
                                setEngIndex(null);
                                setEngineeringText(null);
                            }
                            else {
                                setEngIndex(4);
                                setEngineeringText(EngUi)
                            }
                        }} active={engIndex === 4} />
                        <EngineeringCard width={'11vw'} text={"Real-Time Monitoring Dashboards"} clickHandler={() => {
                            if (engIndex === 5) {
                                setEngIndex(null);
                                setEngineeringText(null);
                            }
                            else {
                                setEngIndex(5);
                                setEngineeringText(EngDashboard)
                            }
                        }} active={engIndex === 5} />
                    </EngineeringCardContainer>
                    {engineeringText &&
                        <ModuleEngineeringBorderWrap active={true}>
                            <ModuleEngineeringBorderWrapBody>
                                <ModuleEngineeringBorderWrapBodyText>
                                    {engineeringText}
                                </ModuleEngineeringBorderWrapBodyText>
                            </ModuleEngineeringBorderWrapBody>
                        </ModuleEngineeringBorderWrap>
                    }
                </EngineeringBody>
            </TrainingTitle>
        </EngineeringBackground>
        {/* Ai */}
        <AIBackground ref={isMobile ? undefined : aiRef}>
            <EngImg2 />
            <TrainingTitle cardIndex={aiText}>
                <EngineeringEyeIcon src={aiWhite} />
                <EngineeringBody cardIndex={aiText}>
                    <EngineeringBodyHead>
                        AI & MACHINE LEARNING
                    </EngineeringBodyHead>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <EngineeringImage width={'59vw'} src={eng2} />
                    </WrapperDiv>
                    <EngineeringCardContainer>
                        <EngineeringCard width={'18.8vw'} text={"Log Pattern Analysis"} clickHandler={() => {
                            if (aiIndex === 1) {
                                setAiIndex(null);
                                setAiText(null);
                            }
                            else {
                                setAiIndex(1);
                                setAiText(aiLog)
                            }
                        }} active={aiIndex === 1} />
                        <EngineeringCard width={'18.8vw'} text={"Big Data Analytics & Actionable Insights"} clickHandler={() => {
                            if (aiIndex === 2) {
                                setAiIndex(null);
                                setAiText(null);
                            }
                            else {
                                setAiIndex(2);
                                setAiText(aiBigData)
                            }
                        }} active={aiIndex === 2} />
                        <EngineeringCard width={'18.8vw'} text={"Advanced Data Crawlers & Smart Parsing"} clickHandler={() => {
                            if (aiIndex === 3) {
                                setAiIndex(null);
                                setAiText(null);
                            }
                            else {
                                setAiIndex(3);
                                setAiText(aiAdvancedData)
                            }
                        }} active={aiIndex === 3} />
                    </EngineeringCardContainer>

                    {aiText &&
                        <ModuleEngineeringBorderWrap active={true}>
                            <ModuleEngineeringBorderWrapBody>
                                <ModuleEngineeringBorderWrapBodyText>
                                    {aiText}
                                </ModuleEngineeringBorderWrapBodyText>
                            </ModuleEngineeringBorderWrapBody>
                        </ModuleEngineeringBorderWrap>
                    }
                </EngineeringBody>
            </TrainingTitle>
        </AIBackground>
        {/* consulting */}
        <ConsultingBackground ref={isMobile ? undefined : consultRef}>
            <EngImg3 />
            <ConstTitle cardIndex={consText}>
                <ConstEyeIcon src={consWhite} />
                <ConstBody cardIndex={consText}>
                    <EngineeringBodyHead>
                        CONSULTING
                    </EngineeringBodyHead>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <EngineeringImage width={'59vw'} src={eng3} />
                    </WrapperDiv>

                    <EngineeringCardContainer>
                        <EngineeringCard isConsultSection={true} width={'18vw'} text={"Security by Design"} clickHandler={() => {
                            if (consIndex === 1) {
                                setConsIndex(null);
                                setConsText(null);
                            }
                            else {
                                setConsIndex(1);
                                setConsText(consSec)
                            }
                        }} active={consIndex === 1} />
                        <EngineeringCard isConsultSection={true} width={'18vw'} text={"Performance Assessment"} clickHandler={() => {
                            if (consIndex === 2) {
                                setConsIndex(null);
                                setConsText(null);
                            }
                            else {
                                setConsIndex(2);
                                setConsText(consPerform)
                            }
                        }} active={consIndex === 2} />
                        <EngineeringCard isConsultSection={true} width={'18vw'} text={"CTO as a Service"} clickHandler={() => {
                            if (consIndex === 3) {
                                setConsIndex(null);
                                setConsText(null);
                            }
                            else {
                                setConsIndex(3);
                                setConsText(consCto)
                            }
                        }} active={consIndex === 3} />
                    </EngineeringCardContainer>

                    <EngineeringCardContainer>
                        <EngineeringCard isConsultSection={true} width={'18vw'} text={"User Acceptance Tests"} clickHandler={() => {
                            if (consIndex === 4) {
                                setConsIndex(null);
                                setConsText(null);
                            }
                            else {
                                setConsIndex(4);
                                setConsText(consUser)
                            }
                        }} active={consIndex === 4} />
                        <EngineeringCard isConsultSection={true} width={'18vw'} text={"Virtual Scrum Master"} clickHandler={() => {
                            if (consIndex === 5) {
                                setConsIndex(null);
                                setConsText(null);
                            }
                            else {
                                setConsIndex(5);
                                setConsText(consVirtual)
                            }
                        }} active={consIndex === 5} />
                        <EngineeringCard isConsultSection={true} width={'18vw'} text={"Digital Transformation"} clickHandler={() => {
                            if (consIndex === 6) {
                                setConsIndex(null);
                                setConsText(null);
                            }
                            else {
                                setConsIndex(6);
                                setConsText(consDigital)
                            }
                        }} active={consIndex === 6} />
                    </EngineeringCardContainer>

                    {consText &&
                        <ModuleEngineeringBorderWrap active={true}>
                            <ModuleEngineeringBorderWrapBody>
                                <ModuleEngineeringBorderWrapBodyText>
                                    {consText}
                                </ModuleEngineeringBorderWrapBodyText>
                            </ModuleEngineeringBorderWrapBody>
                        </ModuleEngineeringBorderWrap>
                    }
                </ConstBody>
            </ConstTitle>
        </ConsultingBackground>
        {/* solution */}
        <SolutionsBackground ref={isMobile ? undefined : solutionRef}>
            <EngImg4 />
            <TrainingTitle cardIndex={solText}>
                <EngineeringEyeIcon src={whitePuzzle} />
                <EngineeringBody cardIndex={solText}>
                    <EngineeringBodyHead>
                        Solutions
                    </EngineeringBodyHead>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <EngineeringImage width={'59vw'} src={eng4} />
                    </WrapperDiv>
                    <EngineeringCardContainer>
                        <EngineeringCard text={"SmartMail - Mass Mailing"} width={'13vw'} clickHandler={() => {
                            if (solIndex === 1) {
                                setSolIndex(null);
                                setSolText(null);
                            }
                            else {
                                setSolIndex(1);
                                setSolText(solSmart)
                            }
                        }} active={solIndex === 1} />
                        <EngineeringCard text={"EOMP - Electoral Operation Management Platform"} width={'13vw'} clickHandler={() => {
                            if (solIndex === 2) {
                                setSolIndex(null);
                                setSolText(null);
                            }
                            else {
                                setSolIndex(2);
                                setSolText(solEomp)
                            }
                        }} active={solIndex === 2} />
                        <EngineeringCard text={"ERP/CRM Implementation"} width={'13vw'} clickHandler={() => {
                            if (solIndex === 3) {
                                setSolIndex(null);
                                setSolText(null);
                            }
                            else {
                                setSolIndex(3);
                                setSolText(solErp)
                            }
                        }} active={solIndex === 3} />
                        <EngineeringCard text={"Secure Private Hosting - SaaS/PaaS/IaaS"} width={'13vw'} clickHandler={() => {
                            if (solIndex === 4) {
                                setSolIndex(null);
                                setSolText(null);
                            }
                            else {
                                setSolIndex(4);
                                // Is this a typo?
                                setSolText(solSec)
                            }
                        }} active={solIndex === 4} />
                    </EngineeringCardContainer>
                    {solText &&
                        <ModuleEngineeringBorderWrap active={true}>
                            <ModuleEngineeringBorderWrapBody>
                                <ModuleEngineeringBorderWrapBodyText>
                                    {solText}
                                </ModuleEngineeringBorderWrapBodyText>
                            </ModuleEngineeringBorderWrapBody>
                        </ModuleEngineeringBorderWrap>
                    }
                </EngineeringBody>
            </TrainingTitle>
        </SolutionsBackground>
        <EngineeringBodyMobile>
            <EngineeringBodyMobileImg />
            <AdvisoryDivLineItemsMobile marginTop={'20vh'} />
            {/* isOpen={isOpen === 1} setOpen={() => setOpen(1)} setClose={() => setOpen(null)}
            isOpen={isOpen === 2} setOpen={() => setOpen(2)} setClose={() => setOpen(null)}
            isOpen={isOpen === 3} setOpen={() => setOpen(3)} setClose={() => setOpen(null)}
            isOpen={isOpen === 4} setOpen={() => setOpen(4)} setClose={() => setOpen(null)} */}
            <EngineeringCardMobile refA={isMobile ? engineeringRef : undefined} logo={engLogo} title={"Engineering"} items={EngineeringItems} />
            <EngineeringCardMobile refA={isMobile ? aiRef : undefined} logo={aiLogo} title={"AI & Machine Learning"} items={AIItems} />
            <EngineeringCardMobile refA={isMobile ? consultRef : undefined} logo={consLogo} title={"Consulting"} items={ConsultingItems} />
            <EngineeringCardMobile refA={isMobile ? solutionRef : undefined} logo={solLogo} title={"Solutions"} items={SolutionsItems} />
        </EngineeringBodyMobile>
        <FooterEngineeringBackground>
            <FooterEngineeringTitle>
                <PotechQuoteFooterEngineering>
                    “We are a team of gurus on a constant quest for exciting challenges and cutting-edge technologies. Intricate problems drive us. Because we enjoy taking them down with our creatively engineered solutions.”
                    <Author>
                        <div>Nadim Henoud</div>
                        <div>Managing Partner</div>
                    </Author>
                </PotechQuoteFooterEngineering>
                <PotechHistoryFooterEngineering>
                    <PotechHistoryFooterAdvisoryImage src={obSoftBlack} />
                    {/*<img src={obSoftBlack} alt="" style={{ marginBottom: '5vh', width: '20vw' }} />*/}

                    <PotechHistoryFooterNewLine>OBSoft is the proud engineering arm of Potech. Founded since 2014, the company provides Machine Learning, AI, and Software Engineering focused on secure, optimized and robust solutions.</PotechHistoryFooterNewLine>
                    <PotechHistoryFooterNewLine>Today presenting a portfolio of more than 40 projects delivered to 25 clients spread in various countries, OBSoft is actively on course with digital transformation, always in sync with the latest technologies.</PotechHistoryFooterNewLine>
                </PotechHistoryFooterEngineering>
            </FooterEngineeringTitle>
        </FooterEngineeringBackground>
        <FooterDivider />
    </>
}

export default Engineering;
