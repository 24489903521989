import { useEffect } from "react";
import { PTextHead, PrivacyParagrapgh, PrivacyPolicyBackground, PrivacyTitle } from "./PrivacyPolicyElements";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return <PrivacyPolicyBackground>
        <Helmet>
            <title>POTECH: Your privacy is our top priority</title>
            <meta name="description" content="Read our Privacy Policy to learn more about your privacy rights and how to exercise them." />
        </Helmet>
        <PTextHead>
            Privacy Notice
        </PTextHead>
        <div>
            <PrivacyParagrapgh>
                POTECH (“we” or “us”) sincerely appreciate your trust and confidence in our goods and services.
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                POTECH takes the protection of your privacy very seriously. It is equally important to us that you understand how we handle the information. We will only use your personal information to deliver the services you have requested from us.
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                By accessing, browsing, using this website/services or clicking “submit”, you expressly acknowledge that you have read, understand and agree to all the terms of this Privacy Notice, as outlined here and as it may be modified by POTECH from time to time without prior notice. If you do not agree to all these terms, please do not browse or use this website/service.
            </PrivacyParagrapgh>
            <PrivacyTitle>
                Collection of Information
            </PrivacyTitle>
            <PrivacyParagrapgh>
                The information we collect depends upon your relationship with us and what you do when visiting our website. We collect information through our website on visitors, clients, business contacts, and others who have or are seeking a relationship with POTECH. As a visitor to of our website, we may ask you to provide specific information about yourself when you engage in certain activities. Although it is optional for you to disclose the requested information, certain information will be required as to enable you to engage in certain activities on our website or maintain a relationship with us (using our services, contacting us, etc.).
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                If you send information to POTECH you explicitly consent to storage, destruction, processing, disclosure and/or any other use of your information by [COMPANY NAME] or its third-party service providers. If you provide us with personal data of another person (for instance, a potential employee/referral), you are solely responsible for ensuring that such person is made aware of the information contained in this Privacy Notice and that the person has given you his/her consent for sharing the information with POTECH.
            </PrivacyParagrapgh>
            <PrivacyTitle>
                Cookies
            </PrivacyTitle>
            <PrivacyParagrapgh>
                We may also acquire certain information about you from a "cookie" and from your IP/Internet address. Cookies are small pieces of information that are stored by your web browser on your computer's hard drive and help us to serve you faster and with better quality.
            </PrivacyParagrapgh>
            <PrivacyTitle>
                Data You Provide to Us
            </PrivacyTitle>
            <PrivacyParagrapgh>
                The personal information we collect from you will vary depending on which services you engage us to deliver. The personal information we collect might include your name, email address, your IP address, which pages you may have visited on our website and when you accessed them.
            </PrivacyParagrapgh>
            <PrivacyTitle>
                Purposes of Using Your Personal Data
            </PrivacyTitle>
            <PrivacyParagrapgh>
                POTECH uses your personal data only when required for specific purposes. Below is the list of purposes for which POTECH uses your personal data:
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                <div>· managing a contractual or employment relationship with you</div>
                <div>· maintaining our records in accordance with applicable legal and regulatory obligations</div>
                <div>· preventing and detecting cyber attacks</div>
                <div>· performing cyber threat intelligence</div>
                <div>· providing you with better customer support</div>
                <div>· sending you cybersecurity related newsletters</div>
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                We may be required by law and other regulatory requirements to retain your data notwithstanding the cessation of the relationship between you and POTECH. The period of retention required may vary depending on the applicable legislation.
            </PrivacyParagrapgh>
            <PrivacyTitle>
                Disclosure of Information
            </PrivacyTitle>
            <PrivacyParagrapgh>
                The information that we obtain from you is necessary for us to provide you with the services. As a general rule, POTECH will not sell, trade or disclose to third parties personal information gathered online about you without your consent. The only exceptions to this rule are: (i) when we work with affiliated businesses, partners or agents to develop a relationship with you or to perform services for you on our behalf; or (ii) as may be required by subpoena, search warrant or other legal process or in the case of imminent physical harm to you or others. In the case of (i) above, we require the relevant businesses, partners or agents to adhere to our policies regarding the use and protection of your personal information. To update any of your personal information or to change any of your marketing preferences, you may contact us using the ‘Contact information’ noted below.
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                Any staff of POTECH with access to your information has a duty of confidentiality under the ethical standards that this company is required to follow.
            </PrivacyParagrapgh>
            <PrivacyTitle>
                Third Party Service Providers working on our behalf
            </PrivacyTitle>
            <PrivacyParagrapgh>
                We may pass your information to our third-party service providers, agents, subcontractors and other associated organizations for the purposes of completing tasks and providing services to you on our behalf, for example to perform a cyber threat intelligence. However, when we use third party service providers, we disclose only the personal information that is necessary to deliver the service and we have a contract in place that requires them to keep your information secure and not to use it for their own purposes. Please be assured that we restrict how such service providers may access, use and disclose your information, and we will not release your information to third parties unless you have requested that we do so, or we are required to do so by law, for example, by a court order or for the purposes of prevention and detection of crime, fraud or corruption.
            </PrivacyParagrapgh>
            <PrivacyTitle>
                How you can access and update your information
            </PrivacyTitle>
            <PrivacyParagrapgh>
                Keeping your information up to date and accurate is important to us. If any of your information changes, please email or write to us, or call us using the ‘Contact information’ noted below.
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                You have the right to ask for a copy of the information POTECH holds about you.
            </PrivacyParagrapgh>
            <PrivacyTitle>
                Security of your personal information
            </PrivacyTitle>
            <PrivacyParagrapgh>
                Whilst we strive to protect your personal information, we cannot guarantee the security of any information you transmit to us, and any transmission by you of information to us shall be done at your own risk.
            </PrivacyParagrapgh>
            <PrivacyParagrapgh>
                Once we receive your information, we make our reasonable efforts to ensure its security on our systems. Where we have given, or where you have chosen, a password which enables you to access information, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
            </PrivacyParagrapgh>
        </div>
    </PrivacyPolicyBackground>
}

export default PrivacyPolicy;