import { ProductContainer, ProductDesc } from "../ProductElements";
import TacivoarLogo from '../../assets/logos/TacivoarLogo.png';
const TacivoarProduct = ({ TacivoarItems }) => {
    return <ProductContainer>
        <ProductDesc>
            <img style={{ width: '11vw', marginRight: 10 }} src={TacivoarLogo} alt="" />
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                <h1 style={{ width: '10vw', color: '#002F87', fontSize: '1.5vw', marginTop: '1vh', fontWeight: 'normal' }}>
                    INSIDE-OUT <br /> CONTROL
                </h1>
                <h2 style={{ width: '15vw', marginTop: '20px', fontSize: '1vw', fontWeight: 'normal', marginBottom: 0 }}>
                    A SIEM++  solution to ensure omnipresent control throughout cloud and on premise systems, with real-time threat hunting and instant security incident response capabilities.
                </h2>
            </div>
        </ProductDesc>
        {TacivoarItems}
    </ProductContainer>
}

export default TacivoarProduct;