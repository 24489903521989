import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { HrefLink } from '../pages/contactUs/ContactUsElement';
import { matchPath, useLocation } from 'react-router-dom';

const DialogBackground = styled.div`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    display: ${props => props.open ? 'block' : 'none'};
    background-color: white;
    z-index: 10;
    width: 40%;
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    border-radius: 10px;
    @media screen and (max-width: 800px) {
		width: 100%;
	}
`;
const ActionButton = styled.div`
    border-radius: 0.375rem;
    color: white;
    padding: 5px;
    width: 100px;
    margin-inline: 30px;
    text-align: center;
    background: var(--Ocean-Gradient, linear-gradient(90deg, #00ADBB 0%, #002F87 100%));
    background-blend-mode: multiply;
    cursor: pointer;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
`
    ;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({reshowDialog, setReshowDialog}) {
    const { pathname } = useLocation();
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        if (localStorage.getItem('cookie') === null && (!matchPath('/cookie_policy', pathname) && !matchPath('/privacy_notice', pathname))) {
            setOpen(true);
        }
    }, [pathname])

    React.useEffect(() => {
        if (localStorage.getItem('cookie') === '0' && reshowDialog === true && (!matchPath('/cookie_policy', pathname) && !matchPath('/privacy_notice', pathname))) {
            setOpen(true);
            setReshowDialog(false);
        }
    }, [pathname, reshowDialog])

    const handleClose = () => {
        localStorage.setItem('cookie', 0);
        setOpen(false);
    };

    const handleAccept = () => {
        localStorage.setItem('cookie', 1);
        setOpen(false);
    };

    return (
        // <React.Fragment>
        //     <Dialog
        //         open={open}
        //         TransitionComponent={Transition}
        //         keepMounted
        //         onClose={handleClose}
        //         aria-describedby="alert-dialog-slide-description"
        //     >
        //         <DialogTitle sx={{
        //             textAlign: 'center',
        //             color: '#002F87',
        //             textTransform: 'uppercase',
        //             marginTop: 2
        //         }} >{"Cookie Policy Notice"}</DialogTitle>
        //         <DialogContent>
        //             <DialogContentText id="alert-dialog-slide-description"
        //                 sx={{
        //                     color: 'black',
        //                     textAlign: 'center',
        //                     fontSize: 14
        //                 }}>
        //                 We take your online privacy and data security seriously at Potech. To enhance your experience on our website, we use cookies and similar technologies. By continuing to browse, you agree to our use of cookies as described in our <HrefLink href='/cookie_policy' target='_blank'>Cookie Policy</HrefLink>.
        //             </DialogContentText>
        //         </DialogContent>
        //         <DialogActions sx={{ justifyContent: 'center', marginBottom: 3 }}>
        //             <ActionButton onClick={handleAccept}>ACCEPT</ActionButton>
        //             <ActionButton onClick={handleAccept}>DECLINE</ActionButton>
        //         </DialogActions>
        //     </Dialog>
        // </React.Fragment>
        <React.Fragment>
            <DialogBackground
                open={open}
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    color: '#002F87',
                    textTransform: 'uppercase',
                    marginTop: 2
                }} >{"Cookie Policy Notice"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description"
                        sx={{
                            color: 'black',
                            textAlign: 'center',
                            fontSize: 14
                        }}>
                            Your online privacy and data security is of utmost priority. We use cookies and similar technologies to enhance your experience on our website. By continuing to browse, you agree to our <HrefLink href='/cookie_policy' target='_blank'>Cookie Policy</HrefLink>.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', marginBottom: 3 }}>
                    <ActionButton onClick={handleAccept}>ACCEPT</ActionButton>
                    <ActionButton onClick={handleClose}>DECLINE</ActionButton>
                </DialogActions>
            </DialogBackground>
        </React.Fragment>
    );
}
