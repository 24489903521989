import styled from "styled-components";

export const NotFoundBackground = styled.div`
    height: 100vh;
    color: white;
    font-size: 4vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #00ADBB 0%, #002F87 100%);
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: column;
    @media screen and (max-width: 800px) {
        font-size: 40px;
    }
`;