import styled from "styled-components";
import imgCareers from '../../assets/images/CareersBack.png';
import keyboard from '../../assets/images/keyboard.png';
import studentBack from '../../assets/images/studentBack.jpg';
import researchBack from '../../assets/images/researchBack.png';

export const CareersBackground = styled.nav`
  width: 100%;
  height: 90vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CareersCoverDiv = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8.2vw;
  @media screen and (max-width: 800px) {
    margin-bottom: 2vh;
  }
`;

export const CoverCareeresIcon = styled.img`
    width: 6.5vw;
    margin-bottom: 10px;
    filter: drop-shadow(20px 10px 20px rgba(0, 0, 0, 0.3));
    cursor: pointer;
    @media screen and (max-width: 800px) {
      width: 15vw;
      margin-bottom: 5px;
    }
`

export const JobHr = styled.hr`
  width: 100%;
  height: 1px;
  background-color: black;
`;

export const CareersImg = styled.div`
  width: 100vw;
  height: 90vh;
  position: absolute;
  background-image: url(${imgCareers});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-position: center;
`;

export const CareersTitle = styled.div`
  color: white;
  height: 50vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin-top: 10vh;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    margin-top: 0vh;
  }
`;

export const CareersTextTitle = styled.div`
  margin-top: 5vh;
  font-size: 1.5vw;
  font-weight: 400;
  margin-bottom: 5vh;
`;

export const CareersTextBody = styled.div`
  font-size: 1.3vw;
  font-weight: 400;
`;

export const JobsBackground = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: 190vh;
  height: auto;
  /* font-family: 'Helvetica Light'; */
  font-family: "HelveticaLt", Arial, sans-serif;
  position: relative;
  /* &::before {
    content: "";
    background: url(${keyboard}) no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1
  } */
  @media screen and (max-width: 800px) {
    height: auto;
    padding-bottom: 5vh;
  }
`;

export const JobsImg = styled.div`
  width: 100%;
  height: 105%;
  position: absolute;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${keyboard});
  @media screen and (max-width: 800px) {
    height: 105%;
  }
`;

export const JobsTitle = styled.div`
  color: white;
  height: 256vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin-top: 25vh;
  align-items: center;
  align-content: center;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
`;

export const JobsBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: 170vh;
  height: auto;
  width: 75vw;
  /* margin: auto; */
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  margin-top: 16vh;
  margin-bottom: 6vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 800px) {
    height: auto;
    margin-top: 20vh;
    margin-bottom: 5vh ;
  }
`;

export const JobsHeadLogo = styled.img`
  position: absolute;
  width: 200px;
  top: -10vh;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1600px) {
    width: 140px;
    top: -9vh;
  }
`;

export const JobsgHead = styled.div`
  /* Heading H1 */
  font-size: 1.8vw;
  text-transform: uppercase;
  margin-top: 0vh;
  padding-inline: 10%;
  margin-bottom: -10vh;
  z-index: 1;
  color: var(--potech-dark-blue, #002F87);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  @media screen and (max-width: 1600px) {
    margin-top: 6vh;
    margin-bottom: 0vh;
  }

  @media screen and (max-width: 800px) {
    margin-top: 15vh;
    font-size: 20px;
    margin-bottom: 7vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const JobsOppgHead = styled.div`
  /* Heading H1 */
  font-size: 1.8vw;
  text-transform: uppercase;
  margin-top: 13vh;
  margin-bottom: 8vh;
  padding-inline: 10%;
  z-index: 1;
  color: var(--potech-dark-blue, #002F87);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  @media screen and (max-width: 800px) {
    margin-top: 15vh;
    font-size: 20px;
    margin-bottom: 7vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const JobsStudentgHead = styled.div`
  /* Heading H1 */
  font-size: 1.8vw;
  text-transform: uppercase;
  margin-top: 5vh;
  padding-inline: 10%;
  margin-bottom: -10vh;

  color: var(--potech-dark-blue, #002F87);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  @media screen and (max-width: 1600px) {
    margin-top: 9vh;
    margin-bottom: 0vh;
  }

  @media screen and (max-width: 800px) {
    margin-top: 15vh;
    font-size: 20px;
    margin-bottom: 7vh;
    font-family: "HelveticaLt", Arial, sans-serif;
  }
`;

export const JobDiv = styled.div`
  border-radius: 15px;
  background: var(--white, #FFF);
  text-align: start;
  padding: 2%;
  padding-inline: 2%;
  box-sizing: border-box;
  font-size: 1vw;
  /* Drop Shadow Style */
  color: black;
  box-shadow: 10px 10px 20px 2px rgba(0, 0, 0, 0.20);
  width: 60vw;
  font-family: "HelveticaLt", Arial, sans-serif;
  line-height: 150%;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 5vh;
    padding: 5%;
  }
`;

export const JobDivTransparent = styled.div`
  border-radius: 15px;
  text-align: center;
  padding: 3%;
  box-sizing: border-box;
  font-size: 1vw;
  /* Drop Shadow Style */
  color: #27A6B6;
  width: 60vw;
  font-family: Arial, Helvetica, sans-serif;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 5vh;
  }
`;

export const TextCareersHeader = styled.div`
    color: #FFF;
    /* Title-Internal Page */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media screen and (max-width: 800px) {
        font-size: 23px;
        margin-top: 10vh;
        margin-bottom: 3vh;
    }
`;

export const CoverCareersIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    text-align: center;
    width: ${props => props.width ?? '45vw'};
    z-index: 1;
    font-size: 0.9vw;
    font-family: Arial, Helvetica, sans-serif;
    @media screen and (max-width: 800px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
  }
`;

export const ApplicationRef = styled.a`
  color: var(--potech-medium-blue, #0071CE);
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
`;

export const JobBigDiv = styled.div`
  border-radius: 15px;
  background: var(--white, #FFF);
  text-align: start;
  padding-inline: 3%;
  box-sizing: border-box;
  font-size: 1vw;
  /* Drop Shadow Style */
  box-shadow: 10px 10px 20px 2px rgba(0, 0, 0, 0.20);
  width: 60vw;
  height: auto;
  max-height: 83vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 9vh;
  overflow: auto;
  @media screen and (max-width: 800px) {
    margin-bottom: 5vh;
  }
`;

export const NoJobsSection = styled.div`
    text-align: center;
    padding:20px;
    font-size:1.2rem;
    @media screen and (max-width: 800px) {
        height: auto;
    }
`;

export const JobSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 35vh;
  justify-content: space-evenly;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const JobSectionTitle = styled.div`
  color: var(--black, #000);
  font-size: 1.5vw;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-top: 1vh;
    margin-bottom: 3vh;
  }
`;

export const JobSectionLogo = styled.img`
  width: 8vw;
  @media screen and (max-width: 800px) {
    width: 100px;
    margin-bottom: 3vh;
  }
`;

export const JobSectionLocation = styled.div`
  color: var(--Gray-1, #333);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 2vh;
  }
`;

export const JobSectionDesc = styled.div`
  color: var(--Gray-1, #333);
  /* Body 01 */
	font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 3vh;
  }
`;

export const JobReadMore = styled.a`
  color: var(--potech-medium-blue, #0071CE);
	font-family: "HelveticaLt", Arial, sans-serif;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const StudentBackground = styled.nav`
  width: 100%;
  height: 90vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const StudentImg = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  background-image: url(${studentBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100%;
  }
`;

export const StudentBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: 55vh;
  margin-top: 15vh;
  width: 75vw;
  /* margin: auto; */
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  /* margin-top: 25vh; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 800px) {
    height: auto;
    margin-bottom: 10vh;
    margin-top: 20vh;
  }
`;

export const ResearchBackground = styled.nav`
  width: 100%;
  height: 110vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const ResearchImg = styled.div`
  width: 100vw;
  height: 120vh;
  position: absolute;
  background-image: url(${researchBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  @media screen and (max-width: 800px) {
    height: 100%;
  }
`;

export const ResearchBody = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: white;
  height: 80vh;
  margin-top: 10vh;
  width: 75vw;
  /* margin: auto; */
  color: var(--darkivore-dark-blue, #1F3B71);
  border-radius: 15px;
  /* margin-top: 25vh; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 800px) {
    height: auto;
    margin-bottom: 10vh;
    margin-top: 20vh;
  }
`;