import chatIcon from "../../assets/images/chaticon.png";
import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import { Swiper, SwiperSlide } from "swiper/react";
import c1 from '../../assets/logos/c1.png';
import c2 from '../../assets/logos/c2.png';
import c3 from '../../assets/logos/c3.png';
import eyeImage from '../../assets/images/eyeImage.png';
import safeImage from '../../assets/images/safeImage.png';
import chessImage from '../../assets/images/chessImage.png';
import stackImage from '../../assets/images/stackImage.png';
import img1 from '../../assets/images/AdvisorySectionBackground.png';
import img2 from '../../assets/images/ServiceSectionBackground.jpg';
import pcSearchImage from '../../assets/images/pcSearchImage.png';
import castleImage from '../../assets/images/castle.png';
import pcImage from '../../assets/images/pcImage.png';

import BombIcon from '../../assets/logos/BombIcon.png';
import ShareIcon from '../../assets/logos/ShareIcon.png';
import TranslateIcon from '../../assets/logos/TranslateIcon.png';
import EyeIcon from '../../assets/logos/EyeIcon.png';
import LockIcon from '../../assets/logos/LockIcon.png';
import ApiIcon from '../../assets/logos/ApiIcon.png';
import MLIcon from '../../assets/logos/MLIcon.png';
import DBIcon from '../../assets/logos/DBIcon.png';

import { LearnMoreButtonBigSection, ProductCircle, ProductDiv, ProductRectangle, ProductText } from "../../components/ProductElements";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./swiperStyle.css";
// import required modules
import { Autoplay, Pagination } from "swiper";
import { BigSection, BigSectionDescMobile, BigSectionSubTitleMobile, BigSectionSubTitleMobile2, BigSectionTitleMobile, Cart, CartBody, CartContainer, CartHeader, CartImage, CartServiceBody, ChatIcon, ClientCircle, ClientDiv, ClientName, ClientText, CornImgBlue, CornImgBlueMobile, CornMobile, HomeBackground, HomeButton, HomeImg, HomeTextBody, HomeTextTitle, HomeTitle, Img, OurClient, Plus, ProductSection, ProudlyServe, ScrollTop, SectionBody, SectionContainer, SectionContainer2, SectionRow, ServeDesc, ServiceDiv, StatisticDesc, StatisticDiv, StatisticNumber, SwiperConrtainer, SwiperMobile, TrustedBy, WaveImg } from "./HomeElements";
import DarkivoreProduct from "../../components/DarkivoreProduct";
import TacivoarProduct from "../../components/TacivoarProduct";
import { LearnMoreButton } from "../../components/ProductElements";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import ProductCardMobile from "../../components/ProductCardMobile";
import DarkiLogo from "../../assets/logos/DarkivoreLogo.png";
import TacivoarLogo from "../../assets/logos/TacivoarLogo.png";
import { DarkivoreRow } from "../../components/DarkivoreProduct/DarkivoreElements";
import { TacivoarRow } from "../../components/TacivoarProduct/TacivoarElements";
import { useNavigate } from "react-router-dom";
import { AdvisoryDivLineItemsMobile } from "../advisory/AdvisoryElement";
import { useState } from "react";
import { Loader } from "../../components/loader";
import Aos from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

const Home = ({ isScrolled, isOpen }) => {

    const targetSection = useSelector((state) => state.scroll.targetSection);
    const navigate = useNavigate();
    useEffect(() => {
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
    }, [])

    useState(() => {
        Aos.init({ duration: 0 });
    }, []);

    const DarkivoreItems = <>
        <DarkivoreRow>
            <ProductDiv isDarki={true}>
                <ProductCircle img={BombIcon} />
                <ProductText>Hyper Fast Unlimited Takedown</ProductText>
            </ProductDiv>
            <ProductDiv isDarki={true}>
                <ProductCircle img={ShareIcon} />
                <ProductText>Holistic Social Media Intelligence (SOCMINT) Coverage</ProductText>
            </ProductDiv>
            <ProductDiv isDarki={true}>
                <ProductCircle img={TranslateIcon} />
                <ProductText>Multilingual Threat Detection and Data Scavenging</ProductText>
            </ProductDiv>
            <ProductDiv isDarki={true}>
                <ProductCircle img={EyeIcon} />
                <ProductText>Granular Deep and Dark Web Visibility</ProductText>
            </ProductDiv>
        </DarkivoreRow>
        <DarkivoreRow marginTop={'1vh'}>
            <ProductDiv isDarki={true}>
                <ProductCircle img={LockIcon} />
                <ProductText>50+ Commercial, Opensource and CSIRT Threat Feeds</ProductText>
            </ProductDiv>
            <ProductDiv isDarki={true}>
                <ProductCircle img={ApiIcon} />
                <ProductText>Seamless API Integration for Security Orchestration</ProductText>
            </ProductDiv>
            <ProductDiv isDarki={true}>
                <ProductCircle img={MLIcon} />
                <ProductText>Machine Learning and Analyst Curated Results</ProductText>
            </ProductDiv>
            <ProductDiv isDarki={true}>
                <ProductCircle img={DBIcon} />
                <ProductText>PII Exposure Protection</ProductText>
            </ProductDiv>
        </DarkivoreRow>
        <LearnMoreButton to="/darkivore">
            LEARN MORE
        </LearnMoreButton>
    </>;

    const TacivoarItems = <>
        <TacivoarRow>
            <ProductDiv isDarki={false}>
                <ProductRectangle>500+</ProductRectangle>
                <ProductText>Alerting Rules</ProductText>
            </ProductDiv>
            <ProductDiv isDarki={false}>
                <ProductRectangle>50,000+</ProductRectangle>
                <ProductText>Events Per Second Handling Capability</ProductText>
            </ProductDiv>
        </TacivoarRow>
        <TacivoarRow>
            <ProductDiv isDarki={false}>
                <ProductRectangle>10+</ProductRectangle>
                <ProductText>Integration with Third Party Threat Intelligence Sources</ProductText>
            </ProductDiv>
            <ProductDiv isDarki={false}>
                <ProductRectangle>100+</ProductRectangle>
                <ProductText>Log Sources</ProductText>
            </ProductDiv>
        </TacivoarRow>
        <LearnMoreButton to="/tacivoar">
            LEARN MORE
        </LearnMoreButton>
    </>

    const objectRef = useRef(null);

    const scrollHandler = () => {
        objectRef.current.style.transform = `translateY(-${window.scrollY / 2}px)`;
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        return () =>
            window.removeEventListener("scroll", scrollHandler);
    }, []);

    const discoverJump = useRef(null);
    return <>
        <Helmet>
            <title>POTECH: Cybersecurity and Information & Technology solutions</title>
            <meta name="description" content="A group of seven entities, offering a wide range of Cybersecurity and Information & Technology solutions." />
        </Helmet>
        <HomeBackground>
            <HomeImg />
            <WaveImg className="fade-in-complex" ref={objectRef} />
            <HomeTitle>
                <HomeTextTitle>
                    Holistic Security for Seamless<br /> Business Evolution
                </HomeTextTitle>
                {/* <HomeTextBody>
                    Holistic Security for Seamless Business Evolution
                </HomeTextBody> */}
                <HomeButton onClick={() => { discoverJump.current.scrollIntoView({ behavior: 'smooth' }) }}>
                    DISCOVER
                </HomeButton>
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                <ChatIcon isOpen={isOpen} src={chatIcon} />
            </HomeTitle>
        </HomeBackground>
        <ProductSection className="container-1" ref={discoverJump}>
            <CornImgBlue>
                <svg width="5vw" height="64" viewBox="0 0 95 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M93.2442 59.4236C92.3635 60.5719 91.0927 61.5253 89.5607 62.187C88.0286 62.8487 86.29 63.1951 84.5213 63.191H10.7127C8.94399 63.196 7.20501 62.8505 5.67236 62.1895C4.13972 61.5286 2.86799 60.5758 1.98633 59.4279C1.10466 58.2799 0.644456 56.9778 0.652446 55.6537C0.660436 54.3295 1.13634 53.0306 2.03182 51.8887L38.8942 4.16745C39.7767 3.01972 41.0476 2.06639 42.579 1.40351C44.1104 0.740626 45.8481 0.391602 47.617 0.391602C49.386 0.391602 51.1237 0.740626 52.655 1.40351C54.1864 2.06639 55.4574 3.01972 56.3399 4.16745L93.2022 51.8887C94.1045 53.0281 94.584 54.3272 94.5914 55.6518C94.5988 56.9765 94.1337 58.2785 93.2442 59.4236Z" fill="url(#paint0_linear_51_664)" style={{ mixBlendMode: 'normal' }} />
                    <defs>
                        <linearGradient id="paint0_linear_51_664" x1="0.652344" y1="31.7913" x2="94.5915" y2="31.7913" gradientUnits="userSpaceOnUse">
                            <stop offset="0.253125" stopColor="#0071a2" />
                            <stop offset="0.711458" stopColor="#006ba0" />
                        </linearGradient>
                    </defs>
                </svg>
            </CornImgBlue>
            <CornMobile>
                <svg width="46" height="40" viewBox="0 0 46 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M45.3402 37.0986C44.909 37.8203 44.2867 38.4194 43.5365 38.8353C42.7863 39.2511 41.9349 39.4688 41.0689 39.4663H4.92635C4.06024 39.4694 3.2087 39.2522 2.4582 38.8369C1.70769 38.4215 1.08496 37.8227 0.653222 37.1013C0.22149 36.3799 -0.00386255 35.5616 5.00943e-05 34.7294C0.00396274 33.8973 0.237 33.0809 0.675499 32.3633L18.7262 2.37293C19.1584 1.65164 19.7807 1.05252 20.5306 0.635934C21.2805 0.219344 22.1314 0 22.9976 0C23.8638 0 24.7147 0.219344 25.4646 0.635934C26.2145 1.05252 26.8369 1.65164 27.269 2.37293L45.3197 32.3633C45.7615 33.0794 45.9963 33.8958 46 34.7283C46.0036 35.5607 45.7758 36.379 45.3402 37.0986Z" fill="url(#paint0_linear_1_662)" style={{ mixBlendMode: 'normal' }} />
                    <defs>
                        <linearGradient id="paint0_linear_1_662" x1="0" y1="19.7332" x2="46" y2="19.7332" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0075a4" />
                            <stop offset="1" stop-color="#00679e" />
                        </linearGradient>
                    </defs>
                </svg>
            </CornMobile>
            <Swiper
                style={{
                    width: "75%",
                    height: "103vh",
                    backgroundColor: "#fff",
                    borderRadius: '10px',
                    marginTop: '0%',
                    marginBottom: '-1%'
                }}
                slidesPerView={1}
                pagination={{
                    el: '.swiper-custom-pagination',
                    clickable: true
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                }}
                modules={[Autoplay, Pagination]}
                className="productSwiper"
            >
                <SwiperSlide>
                    <DarkivoreProduct DarkivoreItems={DarkivoreItems} />
                </SwiperSlide>
                <SwiperSlide>
                    <TacivoarProduct TacivoarItems={TacivoarItems} />
                </SwiperSlide>
            </Swiper>
            <div className="swiper-custom-pagination" />

            <ProductCardMobile items={DarkivoreItems} logo={DarkiLogo} title={"DISARM CYBERTHREATS AT THE SOURCE"} desc={"Cutting-edge comprehensive Cyberthreat Intelligence and Digital Risk Protection Platform."} />
            <ProductCardMobile items={TacivoarItems} logo={TacivoarLogo} title={"KNOWLEDGE, AT THE FIRST LINES OF DEFENSE"} desc={"A SIEM++  solution to ensure omnipresent control throughout cloud and on premise systems, with real-time threat hunting and instant security incident response capabilities."} />

        </ProductSection>
        <BigSection>
            <Img src={img1} />
            <SectionBody>
                <SectionRow>
                    <StatisticDiv>
                        <StatisticNumber>
                            35<Plus>+</Plus>
                        </StatisticNumber>
                        <StatisticDesc>Countries <br /> Served</StatisticDesc>
                    </StatisticDiv>
                    <StatisticDiv>
                        <StatisticNumber>1800<Plus>+</Plus></StatisticNumber>
                        <StatisticDesc>Projects <br />Executed</StatisticDesc>
                    </StatisticDiv>
                    <StatisticDiv>
                        <StatisticNumber>110<Plus>+</Plus></StatisticNumber>
                        <StatisticDesc>Event< br /> Participations</StatisticDesc>
                    </StatisticDiv>
                    <StatisticDiv>
                        <StatisticNumber>4</StatisticNumber>
                        <StatisticDesc>Scientific Papers<br /> Published</StatisticDesc>
                    </StatisticDiv>
                </SectionRow>
                <SwiperMobile className="container-1">
                    <Swiper
                        style={{
                            width: "100%",
                            marginTop: '6%',
                            // marginBottom: '-1%',
                            // backgroundColor: 'red'
                            paddingInline: '8vw',
                            boxSizing: 'border-box',
                            filter: 'drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15))'
                        }}
                        slidesPerView={1.01}
                        // centeredSlides={true}
                        navigation
                        spaceBetween={20}
                        pagination={{
                            el: '.swiper-mobile',
                            clickable: true
                        }}
                        modules={[Pagination]}
                        className="statSwiper"
                        scrollbar={{ draggable: true }}
                    >
                        <SwiperSlide>
                            <StatisticDiv>
                                <StatisticNumber>35<Plus>+</Plus></StatisticNumber>
                                <StatisticDesc>Countries <br /> Served</StatisticDesc>
                            </StatisticDiv>
                        </SwiperSlide>
                        <SwiperSlide>
                            <StatisticDiv>
                                <StatisticNumber>1800<Plus>+</Plus></StatisticNumber>
                                <StatisticDesc>Projects <br />Executed</StatisticDesc>
                            </StatisticDiv>
                        </SwiperSlide>
                        <SwiperSlide>
                            <StatisticDiv>
                                <StatisticNumber>110<Plus>+</Plus></StatisticNumber>
                                <StatisticDesc>Event< br /> Participations</StatisticDesc>
                            </StatisticDiv>
                        </SwiperSlide>
                        <SwiperSlide>
                            <StatisticDiv>
                                <StatisticNumber>4</StatisticNumber>
                                <StatisticDesc>Scientific Papers <br /> Published</StatisticDesc>
                            </StatisticDiv>
                        </SwiperSlide>
                    </Swiper>

                    <div className="swiper-mobile" />
                </SwiperMobile>
                <TrustedBy>
                    TRUSTED BY
                </TrustedBy>
                <OurClient>
                    Our Clients are the cornerstone of our success. We take great pride in our relationships with them and value the trust they have placed in us.
                </OurClient>
                <SectionRow>
                    <ClientDiv>
                        <ClientCircle img={c1} />
                        <ClientName>
                            Eric Ouisse
                        </ClientName>
                        <ClientText lineHeight={'0'} marginBottom={'4vh'}>
                            Co-founder & CPTO at ZIWO
                        </ClientText>
                        <ClientText>
                            We've been working closely with Potech for a long time, and we're incredibly thankful for their expertise in pen testing and their support in achieving our ISO 27001 certification.
                            <AdvisoryDivLineItemsMobile />
                            They've been a key part of ZIWO's growth, and we truly appreciate their commitment to meeting our objectives on time.                        </ClientText>
                    </ClientDiv>
                    <ClientDiv>
                        <ClientCircle img={c2} />
                        <ClientName>
                            Rudy El Semrany
                        </ClientName>
                        <ClientText lineHeight={'0'} marginBottom={'4vh'}>
                            Information Security Manager at Almabani
                        </ClientText>
                        <ClientText>
                            Working with POTECH has been an exceptional experience. Their dedication and commitment to delivering high-quality services and their support for their partners is truly commendable.
                            <AdvisoryDivLineItemsMobile />
                            I'm proud to endorse POTECH and to be associated with their outstanding team.
                        </ClientText>
                    </ClientDiv>
                    <ClientDiv>
                        <ClientCircle img={c3} />
                        <ClientName>
                            Jean Haber
                        </ClientName>
                        <ClientText lineHeight={'0'} marginBottom={'4vh'}>
                            CIO at Banque Banorient France
                        </ClientText>
                        <ClientText>
                            A partnership based on responsiveness and efficiency. We started collaborating with Potech more than 10 years ago, when we needed a trustworthy cybersecurity partner. Their SIEM and SOC teams swiftly detect vulnerabilities, and more importantly they provide the best solutions to mitigate risks immediately.
                            <AdvisoryDivLineItemsMobile />
                            Today we can focus on business development, relying on Potech to protect our digital environment.<br />
                            I highly recommend their services.
                        </ClientText>
                    </ClientDiv>
                </SectionRow>
                <SwiperMobile className="container-1">
                    <Swiper
                        style={{
                            width: "100%",
                            paddingTop: '10%',
                        }}
                        navigation
                        spaceBetween={20}
                        pagination={{
                            el: '.swiper-client',
                            clickable: true
                        }}
                        modules={[Pagination]}
                        className="clientSwiper"
                        scrollbar={{ draggable: true }}
                    >
                        <SwiperSlide>
                            <ClientDiv>
                                <ClientCircle img={c1} />
                                <ClientName>
                                    Eric Ouisse
                                </ClientName>
                                <ClientText>
                                    Co-founder & CPTO at ZIWO
                                </ClientText>
                                <ClientText marginTop={'20px'} marginBottom={'30px'}>
                                    We've been working closely with Potech for a long time, and we're incredibly thankful for their expertise in pen testing and their support in achieving our ISO 27001 certification.
                                    <AdvisoryDivLineItemsMobile />
                                    They've been a key part of ZIWO's growth, and we truly appreciate their commitment to meeting our objectives on time.
                                </ClientText>
                            </ClientDiv>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ClientDiv>
                                <ClientCircle img={c2} />
                                <ClientName>
                                    Rudy El Semrany
                                </ClientName>
                                <ClientText>
                                    Information Security Manager at Almabani
                                </ClientText>
                                <ClientText marginTop={'20px'} marginBottom={'30px'}>
                                    Working with POTECH has been an exceptional experience. Their dedication and commitment to delivering high-quality services and their support for their partners is truly commendable.
                                    <AdvisoryDivLineItemsMobile />
                                    I'm proud to endorse POTECH and to be associated with their outstanding team.                                </ClientText>
                            </ClientDiv>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ClientDiv>
                                <ClientCircle img={c3} />
                                <ClientName>
                                    Jean Haber
                                </ClientName>
                                <ClientText>
                                    CIO at Banque Banorient France
                                </ClientText>
                                <ClientText marginTop={'20px'} marginBottom={'30px'}>
                                    A partnership based on responsiveness and efficiency. We started collaborating with Potech more than 10 years ago, when we needed a trustworthy cybersecurity partner. Their SIEM and SOC teams swiftly detect vulnerabilities, and more importantly they provide the best solutions to mitigate risks immediately.
                                    <AdvisoryDivLineItemsMobile />
                                    Today we can focus on business development, relying on Potech to protect our digital environment.<br />
                                    I highly recommend their services.
                                </ClientText>
                            </ClientDiv>
                        </SwiperSlide>
                    </Swiper>

                    <div className="swiper-client" />
                </SwiperMobile>
                <SectionContainer>
                    <BigSectionTitleMobile>ADVISORY</BigSectionTitleMobile>
                    <BigSectionSubTitleMobile>SECURITY, PERFORMANCE & RESILIENCE</BigSectionSubTitleMobile>
                    <BigSectionDescMobile>
                        Services that have made their mark in helping customers to rapidly adapt and respond to internal or external changes, and most importantly to counter for disruptions or threats, preserving work continuity with limited impact on the business.
                    </BigSectionDescMobile>
                    <CartContainer>
                        <Cart>
                            <CartHeader>STRATEGY</CartHeader>
                            <Loader marginTop="10vh" />

                            <CartImage src={chessImage} alt="" />
                            <CartBody>Create an effective cybersecurity strategy that sets a global vision and roadmap for your organization.</CartBody>
                        </Cart>
                        <Cart>
                            <CartHeader>DEFENSE</CartHeader>
                            <Loader marginTop="10vh" />

                            <CartImage src={safeImage} alt="" />
                            <CartBody>Develop a security in-depth approach against malicious actors and cyber threats. Stronger cyber defenses at your fingertips.</CartBody>
                        </Cart>
                        <Cart>
                            <CartHeader>VIGILANCE</CartHeader>
                            <Loader marginTop="10vh" />

                            <CartImage src={eyeImage} alt="" />
                            <CartBody>Identify and neutralize any threats to your business before they become a problem, by keeping your environment and team on their cyber feet!</CartBody>
                        </Cart>
                        <Cart>
                            <CartHeader>RESILIENCE</CartHeader>
                            <Loader marginTop="10vh" />

                            <CartImage src={stackImage} alt="" />
                            <CartBody>Prepare for the unexpected with incident response, digital forensics, business continuity planning and cyber liability insurance.</CartBody>
                        </Cart>
                    </CartContainer>
                    <LearnMoreButtonBigSection to="/advisory">
                        LEARN MORE
                    </LearnMoreButtonBigSection>
                </SectionContainer>
            </SectionBody>
        </BigSection >
        <BigSection>
            <Img src={img2} />
            <SectionBody>
                <ProudlyServe>
                    PROUDLY SERVING
                </ProudlyServe>
                <ServeDesc>
                    From its various locations including the UAE, KSA, Lebanon, Cyprus, France and Australia, our Group serves a diverse range of essential industries across 35 countries throughout the Middle East, Africa, Europe, and Australia.
                </ServeDesc>
                <SectionRow marginTop={'3%'}>
                    <ServiceDiv>
                        <StatisticNumber>65<Plus>+</Plus></StatisticNumber>
                        <StatisticDesc>Financial<br /> Institutions</StatisticDesc>
                    </ServiceDiv>
                    <ServiceDiv>
                        <StatisticNumber>50<Plus>+</Plus></StatisticNumber>
                        <StatisticDesc>Telco and IT <br />Digital Solutions</StatisticDesc>
                    </ServiceDiv>
                    <ServiceDiv paddingLeft={'5%'}>
                        <StatisticNumber>25<Plus>+</Plus></StatisticNumber>
                        <StatisticDesc>Education & <br /> Nonprofits</StatisticDesc>
                    </ServiceDiv>
                </SectionRow>
                <SectionRow marginTop={'3%'}>
                    <ServiceDiv>
                        <StatisticNumber>10<Plus>+</Plus></StatisticNumber>
                        <StatisticDesc>Retail<br /> Companies</StatisticDesc>
                    </ServiceDiv>
                    <ServiceDiv>
                        <StatisticNumber>10<Plus>+</Plus></StatisticNumber>
                        <StatisticDesc> Health<br /> Institutions</StatisticDesc>
                    </ServiceDiv>
                    <ServiceDiv paddingLeft={'5%'}>
                        <StatisticNumber>20<Plus>+</Plus></StatisticNumber>
                        <StatisticDesc>Governmental Institutions <br /> & Corporate Services</StatisticDesc>
                    </ServiceDiv>
                </SectionRow>

                <SwiperMobile className="container-serve">
                    <Swiper
                        style={{
                            width: "100%",
                            marginTop: '',
                            // marginBottom: '-1%',
                            // backgroundColor: 'red'
                            paddingInline: '8vw',
                            boxSizing: 'border-box',
                            filter: 'drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15))'
                        }}
                        slidesPerView={1.01}
                        // centeredSlides={true}
                        navigation
                        spaceBetween={20}
                        pagination={{
                            el: '.swiper-serve',
                            clickable: true
                        }}
                        modules={[Pagination]}
                        className="clientSwiper"
                        scrollbar={{ draggable: true }}
                    >
                        <SwiperSlide>
                            <ServiceDiv>
                                <StatisticNumber>65<Plus>+</Plus></StatisticNumber>
                                <StatisticDesc>Financial<br /> Institutions</StatisticDesc>
                            </ServiceDiv>

                        </SwiperSlide>
                        <SwiperSlide>
                            <ServiceDiv>
                                <StatisticNumber>50<Plus>+</Plus></StatisticNumber>
                                <StatisticDesc>Telco and IT <br />Digital Solutions</StatisticDesc>
                            </ServiceDiv>

                        </SwiperSlide>
                        <SwiperSlide>

                            <ServiceDiv>
                                <StatisticNumber>25<Plus>+</Plus></StatisticNumber>
                                <StatisticDesc>Education & <br /> Nonprofits</StatisticDesc>
                            </ServiceDiv>
                        </SwiperSlide>

                        <SwiperSlide>
                            <ServiceDiv>
                                <StatisticNumber>10<Plus>+</Plus></StatisticNumber>
                                <StatisticDesc>Retail<br /> Companies</StatisticDesc>
                            </ServiceDiv>

                        </SwiperSlide>
                        <SwiperSlide>
                            <ServiceDiv>
                                <StatisticNumber>10<Plus>+</Plus></StatisticNumber>
                                <StatisticDesc> Health<br /> Institutions</StatisticDesc>
                            </ServiceDiv>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ServiceDiv>
                                <StatisticNumber>20<Plus>+</Plus></StatisticNumber>
                                <StatisticDesc>Governmental Institutions <br /> & Corporate Services</StatisticDesc>
                            </ServiceDiv>
                        </SwiperSlide>
                    </Swiper>

                    <div className="swiper-serve" />
                </SwiperMobile>

                <SectionContainer2>
                    <BigSectionTitleMobile>MANAGED SERVICES</BigSectionTitleMobile>
                    <BigSectionSubTitleMobile2>STAY AHEAD OF THE LATEST THREATS</BigSectionSubTitleMobile2>
                    <BigSectionDescMobile>
                        Through our state-of-the-art SOC - Security Operations Center - we provide Managed & Fortification Security Services that detect and respond to cybersecurity incidents, and strengthen clients’ security defenses by keeping them ahead of the latest threats.                    </BigSectionDescMobile>
                    <CartContainer justifyContent={'center'}>
                        <Cart marginInline={'1%'}>
                            <CartHeader>DETECT</CartHeader>
                            <Loader marginTop="10vh" />

                            <CartImage src={pcSearchImage} alt="" />
                            <CartServiceBody>Our services include optimized XDR packages as well as full-fledged SOC activities, suited in a way to meet the exact needs of each organization.</CartServiceBody>
                        </Cart>
                        <Cart marginInline={'1%'}>
                            <CartHeader>FORTIFY</CartHeader>
                            <Loader marginTop="10vh" />

                            <CartImage src={castleImage} alt="" />
                            <CartServiceBody>Strengthening security defenses allows for an efficient response to cyberattacks targeting valuable digital assets and risking business interruption.</CartServiceBody>
                        </Cart>
                        <Cart marginInline={'1%'}>
                            <CartHeader>RESPOND</CartHeader>
                            <Loader marginTop="10vh" />
                            <CartImage src={pcImage} alt="" />
                            <CartServiceBody>When security incidents occur, it is always a matter of being solidly prepared for confronting those attacks in the most efficient and alert manner.</CartServiceBody>
                        </Cart>
                    </CartContainer>
                    <LearnMoreButtonBigSection to="/managed-services">
                        LEARN MORE
                    </LearnMoreButtonBigSection>
                </SectionContainer2>
            </SectionBody>
        </BigSection >
    </>
}
export default Home;