import styled from "styled-components";
import { NavLink as BaseNavLink } from "react-router-dom";

export const ProductContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
    height: 100%;
	/* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
`;
export const ProductDesc = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
    justify-content: center;
	align-items: flex-end;
	align-content: center;
	margin-bottom: 5vh;
	margin-left: 3vw;
`;

export const ProductDiv = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: start;
	align-content: stretch;
    text-align: center;
    color: #002F87;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: ${props => props.isDarki ? '20%' : '40%'};
    height: 90px;
	height: 12.8vh;
    font-size: 0.8rem;
    padding-inline: ${props => props.isDarki ? '1vw' : '0.6vw'};
	padding-top: 1%;
    border-radius: 10px;
	@media screen and (max-width: 800px) {
		width: 100%;
		margin-top: 10vh;
		height: 126px;
		padding-top: unset;
		align-items: center;
		/* padding-inline: 0px; */
		box-sizing: border-box;
	}
`;

export const ProductDivSwiperMobile = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
    text-align: center;
    color: #002F87;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	width: 100%;
	height: 126px;
    font-size: 0.8rem;
	padding-top: 1%;
    border-radius: 10px;
	margin-top: 5vh;
	background-color: white;
	cursor: pointer;
`;

export const ProductCircle = styled.div`
	position: absolute;
	top: -30px;
	left: 50%;
	width: 70px;
	height: 70px;

	width: 9vh;
	height: 9vh;

	transform: translateX(-50%);
	background-image: url(${(props) => props.img});
	background-repeat: no-repeat;
  	background-size: cover;
	@media screen and (max-width: 1600px) {
		width: 70px;
		height: 70px;
		top: -29%;
	}
	@media screen and (max-width: 800px) {
		width: 73px;
		height: 73px;
		top: -30%;
	}
`;

export const ProductRectangle = styled.div`
	position: absolute;

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;

	/* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
	font-style: normal;
	font-size: 1.2vw;

	top: -25%;
	left: 50%;
	width: 50%;
	height: 40px;
	
	color: white;
	transform: translateX(-50%);
	background: linear-gradient(90deg, #0071CE 28.96%, #002F87 83.65%);
	background-blend-mode: multiply;
	mix-blend-mode: normal;
	/* White */

	border: 1px solid #FFFFFF;
	/* Icons Drop Shadow Style */

	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 9px;

	@media screen and (max-width: 800px) {
		width: 128px;
		height: 50px;
		top: -25%;
		font-size: 27px;
	}
`;

export const ProductText = styled.div`
	font-size: 0.8vw;
	margin-top: 5vh;
	@media screen and (max-width: 800px) {
		font-size: 14px;
		cursor: pointer;
		margin-top: 1vh;
		padding-inline: 10%;
	}
`;
export const LearnMoreButton = styled(BaseNavLink)`
	font-family: 'Helvetica';
	font-weight: 400;
	cursor: pointer;
	display: flex;
	align-items: center;
	text-decoration: none;
	text-align: center;
	color: #002F87;
	box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.25);
	padding: 0.7%;
	padding-inline: 1%;
	font-size: 1vw;
	padding-inline: 7%;
    border-radius: 7px;
	@media screen and (max-width: 800px) {
		font-size: 12px;
		margin-top: 5vh;
		padding: 3%;
		padding-inline: 9%;
		border-radius: 10px;
	}
`;

export const LearnMoreButtonBigSection = styled(BaseNavLink)`
	font-family: Arial, Helvetica, sans-serif;
	/* font-family: "HelveticaLt", Arial, sans-serif; */

	font-weight: 400;
	cursor: pointer;
	display: flex;
	align-items: center;
	text-align: center;
	text-decoration: none;
	color: #002F87;
	box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.25);
	padding: 1%;
	font-size: 1vw;
	padding-inline: 9%;
    border-radius: 10px;
	@media screen and (max-width: 800px) {
		font-size: 12px;
		margin-top: 5vh;
		padding: 3%;
		padding-inline: 9%;
		border-radius: 5px;
		/* margin-bottom: 2vh; */
	}
`;

export const ContainerButton = styled.div`
	width: 50%;
	display: flex;
	justify-content: space-evenly;
	margin-top: ${props => props.marginTop};
	@media screen and (max-width: 800px) {
		width: 100%;
	}
`;

export const ProductMobileButton = styled.a`
text-decoration: none;
border-radius: 6px;
background-blend-mode: multiply;
/* Drop Shadow Style */
box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
color: var(--black, #000);
text-align: center;
font-weight: 400;
width: ${props => props.width ?? '7vw'};
padding: ${props => props.padding ?? '0.5%'};
font-size: 1vw;
background: white;
color: #1F3B71;
cursor: pointer;
margin-top: 6vh;
font-family: Helvetica;
@media screen and (max-width: 800px) {
	width: min-content;
    font-size: 14px;
    padding: 2%;
    padding-inline: 5%;
    margin-top: 6vh;
  }
`