import styled from "styled-components";

export const ProductNav = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
    justify-content: space-between;
	align-items: center;
    align-content: center;
    margin-bottom: 1%;
    padding-right: 2%;
    width: 100%;
    cursor: auto;
`;

export const ProductRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-end;
    z-index: 1;
`;

export const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: start;
`;

export const ProductTitle = styled.div`
    color: #0071CE;
    font-size: 0.9vw;
    margin-left: 10%;
    margin-bottom: 10%;
`;

export const ProductBody = styled.div`
    color: #565656;
    font-size: 0.9vw;
    margin-left: 10%;
    margin-bottom: 5%;
    cursor: text;
    font-style: normal;
    font-weight: 400;
`;

export const ProductImage = styled.img`
    width: 7vw;
    height: 10%;
`;