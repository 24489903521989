import { useRef } from 'react';
import arrowDown from '../../assets/logos/arrowDown.svg';
import arrowUp from '../../assets/logos/arrowUp.svg';
import { ProductCol, ProductMobileContainer, ProductTitle, ProductDesc, CardBottom } from './ProductCardMobileElements';
import { useState } from 'react';

const ProductCardMobile = (props) => {
    const topCard = useRef(null);
    const [isOpen, setOpen] = useState(false);

    return <ProductMobileContainer onClick={props.clickHandler} active={props.active} ref={topCard}>
        <img style={{ width: '118.343px' }} src={props.logo} alt="" />
        <ProductCol>
            <ProductTitle>
                {props.title}
            </ProductTitle>
            <ProductDesc>
                {props.desc}
            </ProductDesc>
            {isOpen && props.items}
        </ProductCol>
        <CardBottom onClick={() => {
            if (isOpen)
                topCard.current.scrollIntoView({ behavior: 'smooth' });
            setOpen(prev => !prev);
        }}>
            <img src={isOpen ? arrowUp : arrowDown} alt="React Logo" width={20} />
        </CardBottom>
    </ProductMobileContainer>
}

export default ProductCardMobile;