import { ContainerHunting, CoreImg, DarkivoreBackground, DarkivoreButton, DarkivoreContainerHuntingBottom, DarkivoreCoreTextTitle, DarkivoreFooterImage, DarkivoreFooterNewLine, DarkivoreHistoryFooter, DarkivoreImg, DarkivoreLogo, DarkivoreRowAdvantage, DarkivoreTextBody, DarkivoreTextTitle, DarkivoreTitle, DigitalBackground, DigitalBody, DigitalFooterBody, DigitalFooterHeader, DigitalFooterSubTitle, DigitalFooterTitle, DigitalFooterTitleMobile, DigitalHead, DigitalHeadLogo, DigitalHuntingImageDesk, DigitalHuntingImageMob, DigitalImg, FeatureBackground, FeatureBundleBackground, FeatureBundleTitle, FeatureCellBodyMobile, FeatureCellDarkivore, FeatureCellDarkivoreSign, FeatureCellMobile, FeatureCellsContainer, FeatureCellsContainerMobile, FeatureCol, FeatureColMobile, FeatureCover, FeatureDarkivore, FeatureDarkivoreCell, FeatureDarkivoreText, FeatureLogo, FeatureSign, FeatureText, FeatureTextBody, FeatureTextMobile, FeatureTextSubTitle, FeatureTextTitle, FeatureTitle, FeaturelImg, FooterDarkivoreBackground, FooterDarkivoreTitle, HuntingBackground, HuntingCyberHead, HuntingHead, HuntingHeadImage, HuntingHeadLogo, HuntingHeadSwiper, HuntingImg, HuntingText, HuntingTitle, HuntingeBody, HuntingeCyberBody, HuntingeCyberTextBody, ProductDarkiCircle, ProductDarkiDivSwiperMobile, ProductDarkivoreCircle, ProductDarkivoreDiv, ProductDarkivoreText, SwiperDarkiMobile, SwiperHuntingImage, TakeDownImageDesk, TakeDownImageMobile, UlSwiper } from "./DarkivoreElements";
import DarkiLogo from '../../assets/logos/Darkivore White Logo.png';
import { AdvisoryButton, AdvisoryDivLineItemsMobile, CornImg, FooterBackground, FooterTitle, PotechHistoryFooterAdvisory, PotechHistoryFooterAdvisoryImage, PotechHistoryFooterNewLine } from "../advisory/AdvisoryElement";

import hunting1 from '../../assets/images/hunting1.png';
import hunting2 from '../../assets/images/hunting2.png';
import hunting3 from '../../assets/images/hunting3.png';
import huntingHeadLogo from '../../assets/logos/HuntingLogo.png';

import DigitalEx from '../../assets/images/DIGITAL-EXPANSION-COMES-WITH-A-PRICE-Infog 1.png';
import DigitalExMob from '../../assets/images/digitalImageHunting.png';
import DigitalLogo from '../../assets/logos/DigitalHeadLogo.png';

import f1 from '../../assets/logos/f1.png';
import f2 from '../../assets/logos/f2.png';
import f3 from '../../assets/logos/f3.png';
import f4 from '../../assets/logos/f4.png';
import f5 from '../../assets/logos/f5.png';
import f6 from '../../assets/logos/f6.png';
import f7 from '../../assets/logos/f7.png';
import f8 from '../../assets/logos/f8.png';

import No from '../../assets/logos/featureNo.png';
import Yes from '../../assets/logos/featureYes.png';

import BombIcon from '../../assets/logos/BombIcon.png';
import ShareIcon from '../../assets/logos/ShareIcon.png';
import TranslateIcon from '../../assets/logos/TranslateIcon.png';
import EyeIcon from '../../assets/logos/EyeIcon.png';
import LockIcon from '../../assets/logos/LockIcon.png';
import ApiIcon from '../../assets/logos/ApiIcon.png';
import MLIcon from '../../assets/logos/MLIcon.png';
import DBIcon from '../../assets/logos/DBIcon.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./style.css";

// import required modules
import { Autoplay, Pagination } from "swiper";
import { ContainerButton, ProductCircle, ProductDiv, ProductDivSwiperMobile, ProductMobileButton, ProductText } from "../../components/ProductElements";
import "../home/swiperStyle.css";

import TakeDownLogo from '../../assets/logos/takeDownLogo.png';
import TakeDownImage from '../../assets/images/CYBERTHREAT-TAKEDOWN 1.png';
import TakeDownImageMob from '../../assets/images/Dark-Infographic-2-Resp.png';
import HoverIcon from "../../components/InfoIcon";

import thidesoftLogo from '../../assets/logos/ThidesoftLogoHorizontalWhite.svg';
import FooterDivider from "../../components/FooterDivider";

import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import chatIcon from "../../assets/images/chaticon.png";
import { ChatIcon, CornMobile, ScrollTop, SwiperMobile } from "../home/HomeElements";
import { useSelector } from "react-redux";
import FeatureCardMobile from "../../components/FeatureCardMobile";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Loader, WrapperDiv } from "../../components/loader";
import { Helmet } from "react-helmet";

const Darkivore = ({ isScrolled, setIsProductactive }) => {

    const targetSection = useSelector((state) => state.scroll.targetSection);
    const [isOpen, setOpen] = useState(null);

    useEffect(() => {
        setIsProductactive(true);
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
        return () => {
            setIsProductactive(false);
        }
    }, [])

    const BrandProtection = <>
        <FeatureColMobile>
            <FeatureCellMobile>
                BRAND PROTECTION
            </FeatureCellMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f1} />
                    <FeatureCover>
                        Impersonation & <br />
                        SOCMINT
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Actively leveraging Social Media Intelligence (SOCMINT), to eradicate all risks of VIP/Brand impersonation, scamming, account takeover and fraud attempts.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f2} />
                    <FeatureCover>
                        Copyright & <br />
                        Trademark
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Neutralizing any infringement of copyright and trademark, whether targeting your brand image, media content, or exclusive services and products.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f3} />
                    <FeatureCover>
                        Digital Footprint <br />
                        Protection
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'We cover passive and active digital footprints that you leave behind every time you navigate the web. By identifying and cleansing those traces, we protect you from reputational costs, identity theft, BEC (Business Email Compromise) and spamming.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f4} />
                    <FeatureCover>
                        Anti- <br />
                        phishing
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Detecting, analyzing and taking down phishing domains, bogus apps, spoofed subdomains and fake web forms to protect your human factor in addition to your customers’ data.'} />
            </FeatureCellBodyMobile>
        </FeatureColMobile>
    </>

    const Attack = <>
        <FeatureColMobile>
            <FeatureCellMobile>
                ATTACK SURFACE & THREAT INTELLIGENCE
            </FeatureCellMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f4} />
                    <FeatureCover>
                        Anti- <br />
                        phishing
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Detecting, analyzing and taking down phishing domains, bogus apps, spoofed subdomains and fake web forms to protect your human factor in addition to your customers’ data.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f5} />
                    <FeatureCover>
                        Data Breach <br />
                        Protection
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Surveilling leaks in the deep and dark web, retrieving compromised, confidential and sensitive information that includes credentials stuffing, financial leaks, credit card data, source codes and configuration files related to your business.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f6} />
                    <FeatureCover>
                        3rd Party & <br />
                        Cloud Security
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Mapping and protecting large amounts of data you have stored with third parties, software (SaaS) providers and cloud computing platforms.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f7} />
                    <FeatureCover>
                        Attack Surface & <br />
                        Vulnerability Intelligence
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Automating Passive Recon and Enumeration in order to quantify and reduce your attack surface, in addition to identifying threat vectors or vulnerabilities affecting your organization to harden your external posture.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f8} />
                    <FeatureCover>
                        Tactical Cyberthreat <br />
                        Intelligence
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Gathering indicators from Open-Source Intelligence (OSINT), major threat feeds, CSIRT advisories and deep/dark web platforms, to counter any potential harmful event targeting your organization.'} />
            </FeatureCellBodyMobile>
        </FeatureColMobile>
    </>

    const DigitalProtection = <>
        <FeatureColMobile>
            <FeatureCellMobile>
                DIGITAL RISK PROTECTION
            </FeatureCellMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f1} />
                    <FeatureCover>
                        Impersonation & <br />
                        SOCMINT
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Actively leveraging Social Media Intelligence (SOCMINT), to eradicate all risks of VIP/Brand impersonation, scamming, account takeover and fraud attempts.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f2} />
                    <FeatureCover>
                        Copyright & <br />
                        Trademark
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Neutralizing any infringement of copyright and trademark, whether targeting your brand image, media content, or exclusive services and products.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f3} />
                    <FeatureCover>
                        Digital Footprint <br />
                        Protection
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'We cover passive and active digital footprints that you leave behind every time you navigate the web. By identifying and cleansing those traces, we protect you from reputational costs, identity theft, BEC (Business Email Compromise) and spamming.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f4} />
                    <FeatureCover>
                        Anti- <br />
                        phishing
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Detecting, analyzing and taking down phishing domains, bogus apps, spoofed subdomains and fake web forms to protect your human factor in addition to your customers’ data.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f5} />
                    <FeatureCover>
                        Data Breach <br />
                        Protection
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Surveilling leaks in the deep and dark web, retrieving compromised, confidential and sensitive information that includes credentials stuffing, financial leaks, credit card data, source codes and configuration files related to your business.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f6} />
                    <FeatureCover>
                        3rd Party & <br />
                        Cloud Security
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Mapping and protecting large amounts of data you have stored with third parties, software (SaaS) providers and cloud computing platforms.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f7} />
                    <FeatureCover>
                        Attack Surface & <br />
                        Vulnerability Intelligence
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Automating Passive Recon and Enumeration in order to quantify and reduce your attack surface, in addition to identifying threat vectors or vulnerabilities affecting your organization to harden your external posture.'} />
            </FeatureCellBodyMobile>
            <FeatureCellBodyMobile>
                <FeatureTextMobile>
                    <FeatureLogo src={f8} />
                    <FeatureCover>
                        Tactical Cyberthreat <br />
                        Intelligence
                    </FeatureCover>
                </FeatureTextMobile>
                <HoverIcon tooltip={'Gathering indicators from Open-Source Intelligence (OSINT), major threat feeds, CSIRT advisories and deep/dark web platforms, to counter any potential harmful event targeting your organization.'} />
            </FeatureCellBodyMobile>
        </FeatureColMobile>
    </>

    const discoverJump = useRef(null);
    const featureText1 = <span>
        Impersonation & <br />SOCMINT
    </span>
    const featureText2 = <span>
        Copyright & <br />Trademark
    </span>
    const featureText3 = <span>
        Digital Footprint <br />Protection
    </span>
    const featureText4 = <span>
        Anti-<br />phishing
    </span>
    const featureText5 = <span>
        Data Breach <br />Protection
    </span>
    const featureText6 = <span>
        3rd Party & <br />Cloud Security
    </span>
    const featureText7 = <span>
        Attack Surface & <br />Vulnerability Intelligence
    </span>
    const featureText8 = <span>
        Tactical Cyberthreat <br />Intelligence
    </span>
    return <>
        <Helmet>
            <title>Darkivore: Disarm Cyberthreats at The Source</title>
            <meta name="description" content="Cutting-edge comprehensive Cyberthreat Intelligence and Digital Risk Protection Platform." />
        </Helmet>
        <DarkivoreBackground>
            <DarkivoreImg />
            <DarkivoreTitle>
                <DarkivoreLogo src={DarkiLogo} />
                <DarkivoreTextTitle>
                    DISARM CYBERTHREATS AT THE SOURCE
                </DarkivoreTextTitle>
                <DarkivoreTextBody>
                    Cutting-edge comprehensive Cyberthreat Intelligence and Digital Risk Protection Platform.
                </DarkivoreTextBody>
                <DarkivoreButton onClick={() => { discoverJump.current.scrollIntoView({ behavior: 'smooth' }) }}>DISCOVER</DarkivoreButton>
                <ContainerButton>
                    <ProductMobileButton href="/documents/DarkivoreDatasheet.pdf" target="_blank" width={'14vw'} padding={'1%'}>DOWNLOAD DATASHEET</ProductMobileButton>
                    <ProductMobileButton href="mailto:info@potech.global" width={'14vw'} padding={'1%'}>REQUEST A DEMO</ProductMobileButton>
                </ContainerButton>
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                <ChatIcon src={chatIcon} />
            </DarkivoreTitle>
        </DarkivoreBackground>
        <HuntingBackground ref={discoverJump}>
            <HuntingImg />
            <HuntingTitle>
                <CornImg zIndex={-1}>
                    <svg width="5vw" height="64" viewBox="0 0 95 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M93.2442 59.4236C92.3635 60.5719 91.0927 61.5253 89.5607 62.187C88.0286 62.8487 86.29 63.1951 84.5213 63.191H10.7127C8.94399 63.196 7.20501 62.8505 5.67236 62.1895C4.13972 61.5286 2.86799 60.5758 1.98633 59.4279C1.10466 58.2799 0.644456 56.9778 0.652446 55.6537C0.660436 54.3295 1.13634 53.0306 2.03182 51.8887L38.8942 4.16745C39.7767 3.01972 41.0476 2.06639 42.579 1.40351C44.1104 0.740626 45.8481 0.391602 47.617 0.391602C49.386 0.391602 51.1237 0.740626 52.655 1.40351C54.1864 2.06639 55.4574 3.01972 56.3399 4.16745L93.2022 51.8887C94.1045 53.0281 94.584 54.3272 94.5914 55.6518C94.5988 56.9765 94.1337 58.2785 93.2442 59.4236Z" fill="url(#paint0_linear_51_664)" style={{ mixBlendMode: 'normal' }} />
                        <defs>
                            <linearGradient id="paint0_linear_51_664" x1="0.652344" y1="31.7913" x2="94.5915" y2="31.7913" gradientUnits="userSpaceOnUse">
                                <stop offset="0.253125" stopColor="#bcbcbc" />
                                <stop offset="0.711458" stopColor="#bababa" />
                            </linearGradient>
                        </defs>
                    </svg>
                </CornImg>
                <CornMobile zIndex={-1}>
                    <svg width="46" height="40" viewBox="0 0 46 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M45.3402 37.0986C44.909 37.8203 44.2867 38.4194 43.5365 38.8353C42.7863 39.2511 41.9349 39.4688 41.0689 39.4663H4.92635C4.06024 39.4694 3.2087 39.2522 2.4582 38.8369C1.70769 38.4215 1.08496 37.8227 0.653222 37.1013C0.22149 36.3799 -0.00386255 35.5616 5.00943e-05 34.7294C0.00396274 33.8973 0.237 33.0809 0.675499 32.3633L18.7262 2.37293C19.1584 1.65164 19.7807 1.05252 20.5306 0.635934C21.2805 0.219344 22.1314 0 22.9976 0C23.8638 0 24.7147 0.219344 25.4646 0.635934C26.2145 1.05252 26.8369 1.65164 27.269 2.37293L45.3197 32.3633C45.7615 33.0794 45.9963 33.8958 46 34.7283C46.0036 35.5607 45.7758 36.379 45.3402 37.0986Z" fill="url(#paint0_linear_1_662)" style={{ mixBlendMode: 'normal' }} />
                        <defs>
                            <linearGradient id="paint0_linear_1_662" x1="0" y1="19.7332" x2="46" y2="19.7332" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#bcbcbc" />
                                <stop offset="1" stop-color="#bababa" />
                            </linearGradient>
                        </defs>
                    </svg>
                </CornMobile>
                <HuntingeBody>
                    <HuntingHeadLogo src={huntingHeadLogo} />
                    <HuntingHead marginTop={'14vh'} marginBottom={'-1.5vh'}>
                        HUNTING HIGH & LOW
                        <br />
                        WITHIN A NEW THREAT LANDSCAPE
                    </HuntingHead>
                    <WrapperDiv>
                        <Loader marginTop="10vh" />
                        <HuntingHeadImage src={hunting1} />
                    </WrapperDiv>
                    <HuntingText width={'60vw'} textAlign={'start'} marginBottom={'2vh'}>
                        With the current digital transformation, security risks are not anymore confined in companies’ internal systems.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'} />
                        Hackers are spreading their tentacles on all digital platforms, including those related to remote working and cloud usage, in addition to the wide variety of social media channels that all businesses are activating.
                    </HuntingText>
                    <ContainerHunting className="container-2" marginTop={'-3vh'}>
                        <Swiper
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: true,
                            }}
                            modules={[Autoplay, Pagination]}
                            className="mySwiper"
                            spaceBetween={20}
                        >
                            <SwiperSlide>
                                <WrapperDiv>
                                    <Loader marginTop="10vh" />
                                    <SwiperHuntingImage src={hunting2} />
                                </WrapperDiv>
                            </SwiperSlide>
                            <SwiperSlide>
                                <WrapperDiv>
                                    <Loader marginTop="10vh" />
                                    <SwiperHuntingImage src={hunting3} />
                                </WrapperDiv>
                            </SwiperSlide>
                        </Swiper>
                        <DarkivoreContainerHuntingBottom>
                            <HuntingHeadSwiper marginLeft={'1vw'}>
                                DARKIVORE <br />pre-emptively
                            </HuntingHeadSwiper>
                            <AdvisoryDivLineItemsMobile />
                            <UlSwiper marginTop={'1vh'}>
                                <li>
                                    Neutralizes cyber-attacks such as data breaches, brand impersonations and phishing scams, by swiftly detecting and capturing them from the surface, deep and dark web.
                                </li>
                                <br />
                                <li>
                                    Prevents attacks; the platform monitors your digital footprint, limits breaches and even assists in generating compliance reports.
                                </li>
                            </UlSwiper>
                        </DarkivoreContainerHuntingBottom>
                    </ContainerHunting>
                </HuntingeBody>
            </HuntingTitle>
        </HuntingBackground>
        <DigitalBackground zIndex={'1'}>
            <DigitalImg />
            <HuntingTitle>
                <DigitalBody height={'130vh'}>
                    <DigitalHeadLogo src={DigitalLogo} />
                    <DigitalHead>
                        DIGITAL EXPANSION
                        <br />
                        COMES WITH A PRICE
                    </DigitalHead>
                    <WrapperDiv>
                        <Loader marginTop="20vh" />
                        <DigitalHuntingImageDesk src={DigitalEx} />
                        <DigitalHuntingImageMob src={DigitalExMob} />
                    </WrapperDiv>

                    <DigitalFooterTitle>
                        <DigitalFooterHeader>
                            <DigitalFooterTitleMobile>
                                Classic Data Center
                            </DigitalFooterTitleMobile>
                            <DigitalFooterSubTitle>
                                Agile environments expose your classic data centers to permanent security challenges. Constant updates, continuous changes and rapid release management widens your attack surface, becoming a burden for your security teams.
                            </DigitalFooterSubTitle>
                            <AdvisoryDivLineItemsMobile marginTop={'3vh'} />
                            <DigitalFooterBody>
                                DARKIVORE detects changes affecting your security posture, and makes sure that your processes are applied, preventing anomalies and mishaps.
                            </DigitalFooterBody>
                        </DigitalFooterHeader>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <DigitalFooterHeader>
                            <DigitalFooterTitleMobile>
                                Digital Data Centers Expansion
                            </DigitalFooterTitleMobile>
                            <DigitalFooterSubTitle>
                                Digital environments are dynamic, expanding beyond our visibility. Digital Data Centers are scattered in private clouds, SaaS subscriptions, social media platforms, online storage and other; and attackers are leveraging these different entry points for easier and more dangerous attacks on your organization.
                            </DigitalFooterSubTitle>
                            <AdvisoryDivLineItemsMobile />
                            <DigitalFooterBody>
                                DARKIVORE hunts and neutralizes all threats outside your classic enterprise environment.
                            </DigitalFooterBody>
                            <AdvisoryDivLineItemsMobile />
                        </DigitalFooterHeader>
                    </DigitalFooterTitle>
                </DigitalBody>
            </HuntingTitle>
        </DigitalBackground>
        <FeatureBundleBackground zIndex={'0'}>
            <FeaturelImg />
            <FeatureBundleTitle>
                <FeatureTextTitle>
                    FEATURES & BUNDLES
                </FeatureTextTitle>
                <FeatureTextBody lineHeight={'150%'}>
                    DARKIVORE offers a range of features that cater to various entities and organizations, accessible through three distinct bundles: the comprehensive Digital Risk Protection bundle, the Brand Protection bundle, and the Attack Surface & Threat Intelligence bundle. This ensures that different types of needs are effectively addressed.
                </FeatureTextBody>
                <FeatureCellsContainer>
                    <FeatureDarkivore width={'22.383vw'}>
                        <FeatureDarkivoreCell>
                            FEATURE
                        </FeatureDarkivoreCell>
                        <FeatureCellDarkivore>
                            <FeatureLogo src={f1} />
                            <FeatureDarkivoreText>
                                <FeatureCover>
                                    <HoverIcon text={featureText1} tooltip={'Actively leveraging Social Media Intelligence (SOCMINT), to eradicate all risks of VIP/Brand impersonation, scamming, account takeover and fraud attempts.'} />
                                </FeatureCover>
                            </FeatureDarkivoreText>
                        </FeatureCellDarkivore>
                        <FeatureCellDarkivore>
                            <FeatureLogo src={f2} />
                            <FeatureDarkivoreText>
                                <FeatureCover>
                                    <HoverIcon text={featureText2} tooltip={'Neutralizing any infringement of copyright and trademark, whether targeting your brand image, media content, or exclusive services and products.'} />
                                </FeatureCover>
                            </FeatureDarkivoreText>
                        </FeatureCellDarkivore>
                        <FeatureCellDarkivore>
                            <FeatureLogo src={f3} />
                            <FeatureDarkivoreText>
                                <FeatureCover>
                                    <HoverIcon text={featureText3} tooltip={'We cover passive and active digital footprints that you leave behind every time you navigate the web. By identifying and cleansing those traces, we protect you from reputational costs, identity theft, BEC (Business Email Compromise) and spamming.'} />
                                </FeatureCover>
                            </FeatureDarkivoreText>
                        </FeatureCellDarkivore>
                        <FeatureCellDarkivore>
                            <FeatureLogo src={f4} />
                            <FeatureDarkivoreText>
                                <FeatureCover>
                                    <HoverIcon text={featureText4} tooltip={'Detecting, analyzing and taking down phishing domains, bogus apps, spoofed subdomains and fake web forms to protect your human factor in addition to your customers’ data.'} />
                                </FeatureCover>
                            </FeatureDarkivoreText>
                        </FeatureCellDarkivore>
                        <FeatureCellDarkivore>
                            <FeatureLogo src={f5} />
                            <FeatureDarkivoreText>
                                <FeatureCover>
                                    <HoverIcon text={featureText5} tooltip={'Surveilling leaks in the deep and dark web, retrieving compromised, confidential and sensitive information that includes credentials stuffing, financial leaks, credit card data, source codes and configuration files related to your business.'} />
                                </FeatureCover>
                            </FeatureDarkivoreText>
                        </FeatureCellDarkivore>
                        <FeatureCellDarkivore>
                            <FeatureLogo src={f6} />
                            <FeatureDarkivoreText>
                                <FeatureCover>
                                    <HoverIcon text={featureText6} tooltip={'Mapping and protecting large amounts of data you have stored with third parties, software (SaaS) providers and cloud computing platforms.'} />
                                </FeatureCover>
                            </FeatureDarkivoreText>
                        </FeatureCellDarkivore>
                        <FeatureCellDarkivore>
                            <FeatureLogo src={f7} />
                            <FeatureDarkivoreText>
                                <FeatureCover>
                                    <HoverIcon text={featureText7} tooltip={'Automating Passive Recon and Enumeration in order to quantify and reduce your attack surface, in addition to identifying threat vectors or vulnerabilities affecting your organization to harden your external posture.'} />
                                </FeatureCover>
                            </FeatureDarkivoreText>
                        </FeatureCellDarkivore>
                        <FeatureCellDarkivore borderRadius={'0px 0px 15px 15px'}>
                            <FeatureLogo src={f8} />
                            <FeatureDarkivoreText>
                                <FeatureCover>
                                    <HoverIcon text={featureText8} tooltip={'Gathering indicators from Open-Source Intelligence (OSINT), major threat feeds, CSIRT advisories and deep/dark web platforms, to counter any potential harmful event targeting your organization.'} />
                                </FeatureCover>
                            </FeatureDarkivoreText>
                        </FeatureCellDarkivore>
                    </FeatureDarkivore>
                    <FeatureDarkivore>
                        <FeatureDarkivoreCell>
                            BRAND <br />
                            PROTECTION
                        </FeatureDarkivoreCell>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={No} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={No} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={No} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign borderRadius={'0px 0px 15px 15px'}>
                            <FeatureSign src={No} />
                        </FeatureCellDarkivoreSign>
                    </FeatureDarkivore>
                    <FeatureDarkivore>
                        <FeatureDarkivoreCell>
                            ATTACK SURFACE &<br />
                            THREAT INTELLIGENCE
                        </FeatureDarkivoreCell>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={No} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={No} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={No} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign borderRadius={'0px 0px 15px 15px'}>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                    </FeatureDarkivore>
                    <FeatureDarkivore>
                        <FeatureDarkivoreCell>
                            DIGITAL RISK <br />
                            PROTECTION
                        </FeatureDarkivoreCell>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                        <FeatureCellDarkivoreSign borderRadius={'0px 0px 15px 15px'}>
                            <FeatureSign src={Yes} />
                        </FeatureCellDarkivoreSign>
                    </FeatureDarkivore>
                </FeatureCellsContainer>
                <FeatureCellsContainerMobile>
                    <FeatureCardMobile isOpen={isOpen === 1} setOpen={() => setOpen(1)} setClose={() => setOpen(null)} title=" BRAND PROTECTION" items={BrandProtection} />
                    <FeatureCardMobile isOpen={isOpen === 2} setOpen={() => setOpen(2)} setClose={() => setOpen(null)} title="ATTACK SURFACE & THREAT INTELLIGENCE" items={Attack} />
                    <FeatureCardMobile isOpen={isOpen === 3} setOpen={() => setOpen(3)} setClose={() => setOpen(null)} title="DIGITAL RISK PROTECTION" items={DigitalProtection} />
                </FeatureCellsContainerMobile>
            </FeatureBundleTitle>
        </FeatureBundleBackground>
        <FeatureBackground height={'250vh'}>
            <CoreImg />
            <FeatureTitle >
                <DarkivoreCoreTextTitle marginTop={'11vh'}>
                    CORE ADVANTAGES
                </DarkivoreCoreTextTitle>

                {/* Desktop Rows */}
                <AdvisoryDivLineItemsMobile marginTop={'4.5vh'} />
                <DarkivoreRowAdvantage>
                    <ProductDarkivoreDiv isDarki={true}>
                        <ProductDarkivoreCircle img={BombIcon} />
                        <ProductDarkivoreText>Hyper Fast Unlimited Takedown</ProductDarkivoreText>
                    </ProductDarkivoreDiv>
                    <ProductDarkivoreDiv isDarki={true}>
                        <ProductDarkivoreCircle img={ShareIcon} />
                        <ProductDarkivoreText>Holistic Social Media Intelligence (SOCMINT) Coverage</ProductDarkivoreText>
                    </ProductDarkivoreDiv>
                    <ProductDarkivoreDiv isDarki={true}>
                        <ProductDarkivoreCircle img={TranslateIcon} />
                        <ProductDarkivoreText>Multilingual Threat Detection and Data Scavenging</ProductDarkivoreText>
                    </ProductDarkivoreDiv>
                    <ProductDarkivoreDiv isDarki={true}>
                        <ProductDarkivoreCircle img={EyeIcon} />
                        <ProductDarkivoreText>Granular Deep and Dark Web Visibility</ProductDarkivoreText>
                    </ProductDarkivoreDiv>
                </DarkivoreRowAdvantage>
                <DarkivoreRowAdvantage marginTop={'10vh'}>
                    <ProductDarkivoreDiv isDarki={true}>
                        <ProductDarkivoreCircle img={LockIcon} />
                        <ProductDarkivoreText>50+ Commercial, Opensource and CSIRT Threat Feeds</ProductDarkivoreText>
                    </ProductDarkivoreDiv>
                    <ProductDarkivoreDiv isDarki={true}>
                        <ProductDarkivoreCircle img={ApiIcon} />
                        <ProductDarkivoreText>Seamless API Integration for Security Orchestration</ProductDarkivoreText>
                    </ProductDarkivoreDiv>
                    <ProductDarkivoreDiv isDarki={true}>
                        <ProductDarkivoreCircle img={MLIcon} />
                        <ProductDarkivoreText>Machine Learning and Analyst Curated Results</ProductDarkivoreText>
                    </ProductDarkivoreDiv>
                    <ProductDarkivoreDiv isDarki={true}>
                        <ProductDarkivoreCircle img={DBIcon} />
                        <ProductDarkivoreText>PII Exposure Protection</ProductDarkivoreText>
                    </ProductDarkivoreDiv>
                </DarkivoreRowAdvantage>

                {/* swiper mobile */}

                <SwiperDarkiMobile className="container-serve">
                    <Swiper
                        style={{
                            width: "100%",
                            paddingInline: '8vw',
                            boxSizing: 'border-box',
                            filter: 'drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15))'
                        }}
                        slidesPerView={1.01}
                        // centeredSlides={true}
                        navigation
                        spaceBetween={20}
                        pagination={{
                            el: '.darkivore-page',
                            clickable: true
                        }}
                        modules={[Pagination]}
                        className="darkivoreSwiper"
                        scrollbar={{ draggable: true }}
                    >
                        <SwiperSlide>
                            <ProductDarkiDivSwiperMobile isDarki={true}>
                                <ProductDarkiCircle img={BombIcon} />
                                <ProductDarkivoreText>Hyper Fast Unlimited Takedown</ProductDarkivoreText>
                            </ProductDarkiDivSwiperMobile>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ProductDarkiDivSwiperMobile isDarki={true}>
                                <ProductDarkiCircle img={ShareIcon} />
                                <ProductDarkivoreText>Holistic Social Media Intelligence (SOCMINT) Coverage</ProductDarkivoreText>
                            </ProductDarkiDivSwiperMobile>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ProductDarkiDivSwiperMobile isDarki={true}>
                                <ProductDarkiCircle img={TranslateIcon} />
                                <ProductDarkivoreText>Multilingual Threat Detection and Data Scavenging</ProductDarkivoreText>
                            </ProductDarkiDivSwiperMobile>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ProductDarkiDivSwiperMobile isDarki={true}>
                                <ProductDarkiCircle img={EyeIcon} />
                                <ProductDarkivoreText>Granular Deep and Dark Web Visibility</ProductDarkivoreText>
                            </ProductDarkiDivSwiperMobile>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ProductDarkiDivSwiperMobile isDarki={true}>
                                <ProductDarkiCircle img={LockIcon} />
                                <ProductDarkivoreText>50+ Commercial, Opensource and CSIRT Threat Feeds</ProductDarkivoreText>
                            </ProductDarkiDivSwiperMobile>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ProductDarkiDivSwiperMobile isDarki={true}>
                                <ProductDarkiCircle img={ApiIcon} />
                                <ProductDarkivoreText>Seamless API Integration for Security Orchestration</ProductDarkivoreText>
                            </ProductDarkiDivSwiperMobile>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ProductDarkiDivSwiperMobile isDarki={true}>
                                <ProductDarkiCircle img={MLIcon} />
                                <ProductDarkivoreText>Machine Learning and Analyst Curated Results</ProductDarkivoreText>
                            </ProductDarkiDivSwiperMobile>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ProductDarkiDivSwiperMobile isDarki={true}>
                                <ProductDarkiCircle img={DBIcon} />
                                <ProductDarkivoreText>PII Exposure Protection</ProductDarkivoreText>
                            </ProductDarkiDivSwiperMobile>
                        </SwiperSlide>
                    </Swiper>

                    <div className="darkivore-page" />
                </SwiperDarkiMobile>

                <HuntingeCyberBody>
                    <DigitalHeadLogo src={TakeDownLogo} />
                    <HuntingCyberHead>
                        CYBERTHREAT TAKEDOWN
                    </HuntingCyberHead>
                    <FeatureTextSubTitle>
                        Capturing and taking down threat sources and malicious behaviors can be costly and time-consuming.
                    </FeatureTextSubTitle>
                    <HuntingeCyberTextBody>
                        Powered by DARKIVORE Analysts and Threat-Neutralizing Mesh Engine, our Takedown capabilities optimize efforts and incident response time at scale. The takedown conducive results stand out due to our engine's efficiency coupled with the team's continuous guaranteed diligence.
                        <AdvisoryDivLineItemsMobile />
                        DARKIVORE leverages active takedown services (on hourly, daily and weekly basis) to eradicate external threats targeting organizations. This is done while covering a wide spectrum of threat sources, events and behaviors including illegitimate impersonation, malicious content, phishing, fake domains, social media scams and attackers' infrastructure.
                    </HuntingeCyberTextBody>
                    <WrapperDiv>
                        <Loader marginTop="30vh" />
                        <TakeDownImageDesk src={TakeDownImage} />
                        <TakeDownImageMobile src={TakeDownImageMob} />
                    </WrapperDiv>
                </HuntingeCyberBody>
            </FeatureTitle>
        </FeatureBackground>
        <FooterDarkivoreBackground>
            <FooterDarkivoreTitle>
                <DarkivoreHistoryFooter>
                    <DarkivoreFooterImage src={thidesoftLogo} />
                    <DarkivoreFooterNewLine>Member of Potech group, Thidesoft is a Cybersecurity Technology Vendor headquartered in Paris - France, with the mission to provide an arsenal of 360° cyber defense tools.</DarkivoreFooterNewLine>

                    <DarkivoreFooterNewLine>Among others, Thidesoft offers TACIVOAR, DARKIVORE and OCTIVORE, three engineering masterpieces that harness AI, Machine Learning, and cutting-edge technology to protect internal & cloud assets, hunt the web for potential threats targeted at businesses, and respond to security incidents.</DarkivoreFooterNewLine>
                </DarkivoreHistoryFooter>
            </FooterDarkivoreTitle>
        </FooterDarkivoreBackground>
        <FooterDivider />
    </>
}

export default Darkivore;