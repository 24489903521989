// store.js
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import scrollReducer from './scrollSlice';
import jobSlice from './jobSlice';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  scroll: scrollReducer,
  job: jobSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer
})

export const persistor = persistStore(store)
