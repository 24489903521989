import { ACADEMY_CARD_TYPES } from "../../common/constant";
import { AcademyBody, AcademyNav, AcademyRow, AcademyTitle } from "./AcademyNavItemElements";

const AcademyNavItem = ({ handleClick, handleClickCard }) => {
    return <AcademyNav>
        <AcademyRow>
            <AcademyTitle onClick={() => {
                handleClick('training')
            }}>
                TRAINING
            </AcademyTitle>
            <AcademyBody onClick={() => {
                handleClick('training')
            }}>
                <div onClick={() => handleClickCard(ACADEMY_CARD_TYPES.AC_PURPLE)}>
                    Purple Team
                </div>
                <div onClick={() => handleClickCard(ACADEMY_CARD_TYPES.AC_DIGITAL)}>
                    Digital Forensics and Incident Response
                </div>
                <div onClick={() => handleClickCard(ACADEMY_CARD_TYPES.AC_SOC)}>
                    SOC and SIEM Management
                </div>
                <div onClick={() => handleClickCard(ACADEMY_CARD_TYPES.AC_COMP)}>
                    Compliance and Strategy
                </div>
                <div onClick={() => handleClickCard(ACADEMY_CARD_TYPES.AC_MACHINE)}>
                    Machine Learning and Blockchain
                </div>
            </AcademyBody>
        </AcademyRow>
        <AcademyRow style={{ marginRight: '0%' }}>
            <AcademyTitle onClick={() => {
                handleClick('pecb')
            }}
            // style={{ color: '#565656' }}
            >
                PECB TRAINING CERTIFICATIONS
            </AcademyTitle>
            <AcademyBody onClick={() => {
                handleClick('pecb')
            }}>
                {/* <div >
                PECB TRAINING CERTIFICATIONS
                </div> */}
                <div onClick={() => handleClickCard(ACADEMY_CARD_TYPES.CERT_INFO)}>
                    Information Security
                </div>
                <div onClick={() => handleClickCard(ACADEMY_CARD_TYPES.CERT_CYBER)}>
                    Cybersecurity
                </div>
                <div onClick={() => handleClickCard(ACADEMY_CARD_TYPES.CERT_CONT)}>
                    Continuity, Resilience, Recovery
                </div>
                <div onClick={() => handleClickCard(ACADEMY_CARD_TYPES.CERT_GOV)}>
                    Governance, Risk and Compliance
                </div>
                {/* <div style={{ textDecoration: 'underline', color: '#405DF9', marginTop: 25 }}>
                    About PECB
                </div> */}
            </AcademyBody>
        </AcademyRow>
    </AcademyNav>
}

export default AcademyNavItem;