// scrollSlice.js
import { createSlice } from '@reduxjs/toolkit';

const scrollSlice = createSlice({
  name: 'scroll',
  initialState: {
    targetSection: null,
    targetCard: null
  },
  reducers: {
    setTargetSection: (state, action) => {
      state.targetSection = action.payload;
    },
    setTargetCard: (state, action) => {
      state.targetCard = action.payload;
    },
    resetTargetSection: (state) => {
      state.targetSection = null;
    },
    resetTargetCard: (state) => {
      state.targetCard = null;
    }
  },
});

export const { setTargetSection, resetTargetSection, setTargetCard } = scrollSlice.actions;

export default scrollSlice.reducer;
