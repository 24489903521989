import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { HrefLink } from '../pages/contactUs/ContactUsElement';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import { useState } from 'react';

const ActionButton = styled.button`
    background: linear-gradient(90deg, #F1AF5E 38.85%, #E88A0D 71.15%);
    background-blend-mode: multiply;
    /* Drop Shadow Style */
    border: none;
    border-radius: 0.375rem;
    color: white;
    padding: 5px;
    width: 100px;
    margin-inline: 30px;
    text-align: center;
    cursor: pointer;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
    &:active {
        opacity: 0.6;
    }
`
    ;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CaptchaDialog({ open, setOpen, submitHandler, btnText, loading, setCaptchaKey }) {
    function handleClose() {
        setOpen(false);
    }
    const [isVerified, setIsVerified] = useState(false)
    const captchaRef = useRef(null)
    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    color: '#002F87',
                    textTransform: 'uppercase',
                    marginTop: 2
                }} >{"Verify you're a real person"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description"
                        sx={{
                            color: 'black',
                            textAlign: 'center',
                            fontSize: 14
                        }}>
                        <div className="captcha">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_REACPTCHA_SECRET_KEY}
                                onChange={(value) => {
                                    setCaptchaKey(value);
                                    setIsVerified(true);
                                }}
                                ref={captchaRef}
                            />
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', marginBottom: 3 }}>
                    <ActionButton disabled={!isVerified || loading} onClick={submitHandler}>{btnText}</ActionButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
