import { LearnMoreNavButton } from "../Navbar/NavbarElements";
import DarkiLogo from "../../assets/logos/DarkivoreLogo.png";
import TaceLogo from "../../assets/logos/TacivoarLogo.png";
import OctivoreLogo from "../../assets/logos/Octivore Logo.png";
import { ProductBody, ProductContainer, ProductImage, ProductNav, ProductRow, ProductTitle } from "./ProductNavItemElements";

const ProductNavItem = ({ navigate }) => {
    return <ProductNav>
        <ProductRow>
            <ProductImage src={DarkiLogo} alt="" />
            <ProductContainer>
                <ProductTitle>
                    DRP & CTI
                </ProductTitle>
                <ProductBody>
                    <div>
                        Digital Risk Protection
                    </div>
                    <div>
                        Brand Protection
                    </div>
                    <div>
                        Attack Surface & CTI
                    </div>
                    <div>
                        Cyberthreat Takedown
                    </div>
                </ProductBody>
                <LearnMoreNavButton style={{padding: '0.35vw'}} color={"#70AB46"} to="/darkivore">LEARN MORE</LearnMoreNavButton>
            </ProductContainer>
        </ProductRow>
        <ProductRow>
            <ProductImage src={TaceLogo} alt="" />
            <ProductContainer>
                <ProductTitle>
                    SIEM++
                </ProductTitle>
                <ProductBody>
                    <div>
                        Threat Detection
                    </div>
                    <div>
                        Flexible Parsing
                    </div>
                    <div>
                        SOAR Capabilities
                    </div>
                    <div>
                        User Entity & Behavior Analytics
                    </div>
                </ProductBody>
                <LearnMoreNavButton color={"#8EC0E8"} to="/tacivoar">LEARN MORE</LearnMoreNavButton>
            </ProductContainer>
        </ProductRow>
        <ProductRow>
            <ProductImage src={OctivoreLogo} alt="" />
            <ProductContainer>
                <ProductTitle>
                    SECURITY IR
                </ProductTitle>
                <ProductBody>
                    <div>
                        Alerts Aggregation
                    </div>
                    <div>
                        Support for Any SIEM
                    </div>
                    <div>
                        Centralized Ticket Management
                    </div>
                    <br />
                </ProductBody>
                <LearnMoreNavButton color={"#E9D24A"} to="/octivore">LEARN MORE</LearnMoreNavButton>
            </ProductContainer>
        </ProductRow>
    </ProductNav>
}

export default ProductNavItem;