import { ENGINEERING_CARD_TYPES } from "../../common/constant";
import { EngineeringBody, EngineeringNav, EngineeringRow, EngineeringTitle } from "./EngineeringNavItemElements";

const EngineeringNavItem = ({ handleClick, handleClickCard }) => {
    return <EngineeringNav>
        <EngineeringRow>
            <EngineeringTitle onClick={() => {
                handleClick('engineering')
            }}>
                ENGINEERING
            </EngineeringTitle>
            <EngineeringBody onClick={() => {
                handleClick('engineering')
            }}>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.ENG_DATA)}>
                    No-Code Data Flows
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.ENG_WORKFLOW)}>
                    No-Code Workflow Builds
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.ENG_ENGINES)}>
                    High Performance Engines: C, Java, Python
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.ENG_UI)}>
                    Interactive UX/UI Platforms
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.ENG_DASHBOARD)}>
                    Real-Time Monitoring Dashboards
                </div>
            </EngineeringBody>
        </EngineeringRow>
        <EngineeringRow>
            <EngineeringTitle onClick={() => {
                handleClick('ai')
            }}>
                AI & MACHINE LEARNING
            </EngineeringTitle>
            <EngineeringBody onClick={() => {
                handleClick('ai')
            }}>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.AI_LOG)}>
                    Log Pattern Analysis
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.AI_BIG_DATA)}>
                    Big Data Analytics & Actionable Insights
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.AI_ADVANCED_DATA)}>
                    Advanced Data Crawlers & Smart Parsing
                </div>
            </EngineeringBody>
        </EngineeringRow>
        <EngineeringRow>
            <EngineeringTitle onClick={() => {
                handleClick('consult')
            }}>
                CONSULTING
            </EngineeringTitle>
            <EngineeringBody onClick={() => {
                handleClick('consult')
            }}>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.CONSULT_SECURITY)}>
                    Security by Design
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.CONSULT_PERFORMANCE)}>
                    Performance Assessment
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.CONSULT_CTO)}>
                    CTO as a Service
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.CONSULT_USER)}>
                    User Acceptance Tests
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.CONSULT_VIRTUAL)}>
                    Virtual Scrum Master
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.CONSULT_DIGITAL)}>
                    Digital Transformation
                </div>
            </EngineeringBody>
        </EngineeringRow>
        <EngineeringRow>
            <EngineeringTitle onClick={() => {
                handleClick('solution');
            }}>
                SOLUTIONS
            </EngineeringTitle>
            <EngineeringBody onClick={() => {
                handleClick('solution');
            }}>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.SOLUTIONS_SMARTMAIL)}>
                    SmartMail | Mass Mailing
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.SOLUTIONS_EOMP)}>
                    EOMP | Electoral Operation Management Platform
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.SOLUTIONS_ERP)}>
                    ERP/CRM Implementation
                </div>
                <div onClick={() => handleClickCard(ENGINEERING_CARD_TYPES.SOLUTIONS_SECURE)}>
                    Secure Private Hosting | SaaS/PaaS/IaaS
                </div>
            </EngineeringBody>
        </EngineeringRow>
    </EngineeringNav>
}

export default EngineeringNavItem;