import { ResourcesBody, ResourcesNav, ResourcesRow, ResourcesTitle } from "./ResourcesNavItemElements";

const ResourcesNavItem = ({ handleClick }) => {
    return <ResourcesNav>
        <ResourcesRow>
            <ResourcesTitle onClick={() => {
                handleClick('knowledge')
            }}>
                KNOWLEDGE BASE
            </ResourcesTitle>
            <ResourcesBody onClick={() => {
                handleClick('knowledge')
            }}>
                <div>
                    Research & Publications
                </div>
                <div>
                    Datasheets
                </div>
                {/* <div>
                    Blog Posts
                </div> */}
            </ResourcesBody>
        </ResourcesRow>
        {/* <ResourcesRow>
            <ResourcesTitle onClick={() => {
                handleClick('media')
            }}>
                MEDIA
            </ResourcesTitle>
            <ResourcesBody onClick={() => {
                handleClick('media')
            }}>
                <div>
                    News
                </div>
                <div>
                    Events
                </div>
            </ResourcesBody>
        </ResourcesRow> */}
    </ResourcesNav>
}

export default ResourcesNavItem;