import styled from "styled-components";
import StoryBack from '../../assets/images/StoryBack.png';
import QuoteBack from '../../assets/images/QuoteBack.png';

export const AboutUsBackground = styled.nav`
  width: 100%;
  height: 120vh;
  height: 90vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  background: var(--ocean-gradient, linear-gradient(90deg, #00ADBB 0%, #002F87 100%));
  align-items: center;
  display: flex;
  @media screen and (max-width: 800px) {
    height: 100vh;
  }
`;

export const AboutUsTitle = styled.div`
    height: 70vh;
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-top: 14vh;
`;

export const TextAboutHeader = styled.div`
    color: #FFF;
    /* Title-Internal Page */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 0vh;
    margin-bottom: 3vh;
    @media screen and (max-width: 800px) {
        font-size: 23px;
        margin-top: 4vh;
        margin-bottom: 1vh;
    }
`;

export const AboutHead = styled.div`
  text-align: center;
  /* Heading H1 */
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
  /* margin-top: 0vh; */
  margin-bottom: 8vh;
  color: #002F87;
  font-size: 1.8vw;
  @media screen and (max-width: 800px) {
    font-size: 20px;
    font-family: "HelveticaLt", Arial, sans-serif;
    margin-top: 15vh;
  }
`;

export const CompanyLogoContainers = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const AboutUsBody = styled.div`
  border-radius: 15px;
  border: 1px solid var(--white, #FFF);
  background-color: white;
  background-blend-mode: multiply;
  padding: 3%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Testimonials Box Shadow */
  filter: drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15));
  width: 61vw;
  height: 100vh;
  @media screen and (max-width: 800px) {
    height: auto;
    margin-bottom: 7vh;
  }
`;

export const StoryImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${StoryBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

export const StoryBackground = styled.nav`
  width: 100%;
  height: 180vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const StoryContainer = styled.div`
  color: white;
  height: 140vh;
  display: flex;
  border-radius: 15px;
  width: 76vw;
  background-color: white;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  margin-top: 10vh;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
  @media screen and (max-width: 800px) {
    height: auto;
    margin-top: 25vh;
    margin-bottom: 7vh;
  }
`;

export const StoryText = styled.div`
    color: var(--black, #000);

    /* Body 01 */
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @media screen and (max-width: 800px) {
      font-size: 14px;
      /* margin-top: 3vh; */
      text-align: center;
      /* margin-bottom: 3vh; */
      margin-top: 5vh;
      padding-bottom: 5vh;
    }
`;

export const CompanyLogo = styled.img`  
    width: 12%;
    aspect-ratio: 3/2;
    object-fit: contain;
    @media screen and (max-width: 800px) {
      width: 70%;
      margin-top: 5vh;
    }
`;

export const QuoteBackground = styled.nav`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    height: 80vh;
  }
`;

export const QuoteImg = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  background-image: url(${QuoteBack});
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;

export const QuoteText = styled.div`
    color: var(--white, #FFF);
    text-align: center;
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 2.1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80vw;
    @media screen and (max-width: 800px) {
      font-size: 30px;
    }
`;

export const QuoteSayer = styled.div`
    color: var(--white, #FFF);
    text-align: center;

    /* Resp-Title-Internal Page */
    font-family: Helvetica;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 9vh;
    line-height: 150%;
    @media screen and (max-width: 800px) {
      font-size: 18px;
      font-family: "HelveticaLt", Arial, sans-serif;
    }
`;

export const MembersContainer = styled.div`
  color: white;
  height: auto;
  display: flex;
  border-radius: 15px;
  width: 76vw;
  background-color: white;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  /* font-family: 'Helvetica Light'; */
	font-family: "HelveticaLt", Arial, sans-serif;
`;

export const MembersRow = styled.div` 
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap:17vh;
    column-gap:10px;
    justify-content: space-between;
    justify-items: center;
  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    row-gap:20px;
  }
`;

export const MembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 17vh;
  @media screen and (max-width: 800px) {
      margin-bottom: 20px;
  }
`;

export const LinkedInLogo = styled.img`
    width: 70px;
    margin-bottom: 3%;
`;