import styled from "styled-components";

export const JobBackground = styled.div`
    min-height: 100vh;
    color: white;
    font-size: 4vw;
    min-height: 100vh;
    padding-top: 23vh;
    padding-inline: 10vh;

    background: linear-gradient(90deg, #00ADBB 0%, #002F87 100%);
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 10vh;
    @media screen and (max-width: 800px) {
        height: auto;
        padding-inline: 5vh;
        padding-top: 15vh;
    }
`;

export const JobBody = styled.div`
    width: 80vw;
    background-color: white;
    padding: 3%;
    font-size: 1vw;
    color: black;
    filter: drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.15));
    border-radius: 15px;
    margin-top: 10vh;
    @media screen and (max-width: 800px) {
        padding-top: 3vh;
        padding-bottom: 3vh;
        box-sizing: border-box;
    }
`;

export const JobDescriptionText = styled.div`
    color: var(--Gray-1, #333);
    font-family: "HelveticaLt", Arial, sans-serif;
    font-size: 1vw;
    line-height: 150%;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`;