import { ChatIcon, ScrollTop } from "../home/HomeElements";
import {
    ApplicationRef,
    CareersBackground,
    CareersCoverDiv,
    CareersImg,
    CareersTitle,
    CoverCareeresIcon,
    CoverCareersIconContainer,
    JobBigDiv,
    JobDiv,
    JobDivTransparent,
    JobHr,
    JobReadMore,
    JobSection,
    JobSectionDesc,
    JobSectionLocation,
    JobSectionLogo,
    JobSectionTitle,
    JobsBackground,
    JobsBody,
    JobsHeadLogo,
    JobsImg,
    JobsOppgHead,
    JobsStudentgHead,
    JobsgHead,
    ResearchBackground,
    ResearchBody,
    ResearchImg,
    StudentBackground,
    StudentBody,
    StudentImg,
    TextCareersHeader,
    NoJobsSection
} from "./CareersElements";
import chatIcon from "../../assets/images/chaticon.png";
import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import { HrefLink } from "../contactUs/ContactUsElement";
import { AdvisoryDivLineItemsMobile } from "../advisory/AdvisoryElement";
import suitcase from '../../assets/logos/suitcase.png'
import student from '../../assets/logos/student.png'
import paper from '../../assets/logos/paper.png'
import whiteBag from '../../assets/logos/whiteBag.png'
import whiteStudent from '../../assets/logos/whiteStudent.png'
import whitePaperResearch from '../../assets/logos/whitePaperResearch.png'
import FooterDivider from "../../components/FooterDivider";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Careers = ({ isScrolled }) => {

    //     <JobSection>
    //     <JobSectionTitle>
    //         SOC Manager
    //     </JobSectionTitle>
    //     <JobSectionLogo src={itelicLogoLabel} />
    //     <JobSectionLocation>
    //         Saudi Arabia
    //     </JobSectionLocation>
    //     <JobSectionDesc>
    //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos... <JobReadMore>Read more</JobReadMore>
    //     </JobSectionDesc>
    // </JobSection>
    // <JobHr />
    // <JobSection>
    //     <JobSectionTitle>
    //         Senior Developer
    //     </JobSectionTitle>
    //     <JobSectionLogo src={obSoftLogoLabel} />
    //     <JobSectionLocation>
    //         Lebanon
    //     </JobSectionLocation>
    //     <JobSectionDesc>
    //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos... <JobReadMore>Read more</JobReadMore>
    //     </JobSectionDesc>
    // </JobSection>
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const top = useRef(null);
    useEffect(() => {
        top.current.scrollIntoView({ behavior: 'smooth' });
    }, [])

    const job = useRef(null);
    const internship = useRef(null);
    const research = useRef(null);

    const description = <div>
        <div>*** PORTFOLIOS ARE REQUIRED FOR THE APPLICATION PROCESS***</div>
        <div>Applications without portfolios or a portfolio/website link, will not be considered.</div>
        <AdvisoryDivLineItemsMobile />
        <div>We are in search of a creative Junior Multimedia Designer to join our team. If you have a knack for creating impactful visual content and enjoy working across both digital and print
            media, this could be the ideal opportunity for you.</div>
        <div>Responsibilities include:</div>
        <div>- Developing and producing multimedia content for both digital and print platforms.</div>
        <div>- Designing event booths layouts.</div>
        <div>- Crafting engaging brochures, flyers, presentations and other communications.</div>
        <div>- Conceptualizing and producing captivating social media content, spanning static visuals and video creatives.</div>
        <div>- Conducting video editing and animation tasks.</div>
        <div>- Enhancing images through retouching and manipulation.</div>
        <div>- Translating concepts and storyboards into static and video visuals.</div>
        <div>- Ensuring adherence to brand visual identity guidelines.</div>
        <div>- Collaborating with the team to brainstorm innovative ideas.</div>
        <div>- Maintaining comprehensive documentation of design work.</div>
        <AdvisoryDivLineItemsMobile />
        <div>Essential Skills:</div>
        <div>- Excellent communicator</div>
        <div>- High creativity and technical adaptability</div>
        <div>- Curious, self-motivated with a professional can-do attitude</div>
        <div>- Demonstrated creative flair and a keen eye for design.</div>
        <div>- Superior organizational and coordination skills</div>
        <div>- Ability to manage multiple projects simultaneously while meeting deadlines.</div>
        <AdvisoryDivLineItemsMobile />
        <div>Education & Background:</div>
        <div>- Bachelor's Degree in Multimedia Design, Graphic Design or a related field.</div>
        <div>- Minimum 1 year experience in a similar role</div>
        <div>- Proficiency in Adobe Illustrator, Photoshop, InDesign, After Effects and Premiere Pro.</div>
        <div>- Curiosity and experience in AI and CGI techniques.</div>
        <div>- Familiarity with Figma is a plus. UX/UI design is a plus.</div>
        <div>- Fluent English and French, Arabic is highly desirable.</div>
        <AdvisoryDivLineItemsMobile />
        <div>This position is on a full-time basis, with flexible office attendance.</div>
        <AdvisoryDivLineItemsMobile />
        <div>* Make sure to add a link to your portfolio and showreel.</div>
    </div>
    return (
        <>
            <Helmet>
                <title>POTECH Careers: Join our global team of cybersecurity experts</title>
                <meta name="description" content="Join the POTECH team and help us make the world a safer place from cyber threats!" />
            </Helmet>
            <CareersBackground ref={top}>
                <CareersImg />
                <CareersTitle>
                    <TextCareersHeader>
                        Careers
                    </TextCareersHeader>
                    <CoverCareersIconContainer width={'35vw'}>
                        <CareersCoverDiv onClick={() => { job.current.scrollIntoView({ behavior: 'smooth' }) }}>
                            <CoverCareeresIcon src={suitcase} />
                            <div>Job<br /> Opportunities</div>
                        </CareersCoverDiv>
                        <CareersCoverDiv onClick={() => { internship.current.scrollIntoView({ behavior: 'smooth' }) }}>
                            <CoverCareeresIcon src={student} />
                            <div>Student<br /> Internships</div>
                        </CareersCoverDiv>
                        <CareersCoverDiv onClick={() => { research.current.scrollIntoView({ behavior: 'smooth' }) }}>
                            <CoverCareeresIcon src={paper} />
                            <div>Advanced<br />Research Support</div>
                        </CareersCoverDiv>
                    </CoverCareersIconContainer>
                    <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => { window.scrollTo(0, 0) }} />
                    <ChatIcon src={chatIcon} />
                </CareersTitle>
            </CareersBackground>
            <JobsBackground ref={job}>
                <JobsImg />
                <JobsBody>
                    <JobsHeadLogo src={whiteBag} />
                    <JobsOppgHead>
                        Job Opportunities
                    </JobsOppgHead>
                    <JobDiv>
                        Potech is not a workplace. It is a place where collaboration, growth and innovation thrive.
                        <AdvisoryDivLineItemsMobile />
                        If you believe that you have what it takes to hop on and join our exceptionally dynamic team, send your CV to <HrefLink href="mailto:hr@potech.global">hr@potech.global</HrefLink>
                    </JobDiv>
                    <AdvisoryDivLineItemsMobile />
                    <JobDivTransparent>
                        <i>"Being part of our team is akin to joining an exceptional group of cyber rockstars. We wholeheartedly embrace our projects as sacred missions, infusing them with a profound sense of purpose. Every remarkable discovery, meticulous remediation, groundbreaking innovation, or even unforeseen challenge etches its imprint upon the very core of our being.”</i>
                        <AdvisoryDivLineItemsMobile />
                        Elie Zeidan – Managing Partner, THIDESoft
                    </JobDivTransparent>
                    <AdvisoryDivLineItemsMobile />
                    <JobBigDiv>
                        <NoJobsSection>
                            There are no vacancies available for now.
                        </NoJobsSection>
                        {/*<JobSection onClick={() => {*/}
                        {/*    dispatch(setJob({*/}
                        {/*        description: description,*/}
                        {/*        type: "Full-time",*/}
                        {/*        attendance: "Hybrid",*/}
                        {/*        logo: potechLogoLabel,*/}
                        {/*        position: "Junior Multimedia Designer",*/}
                        {/*        country: "Lebanon"*/}
                        {/*    }))*/}
                        {/*    navigate('/job-description');*/}
                        {/*}}>*/}
                        {/*    <JobSectionTitle>*/}
                        {/*        Junior Multimedia Designer*/}
                        {/*    </JobSectionTitle>*/}
                        {/*    <JobSectionLogo src={potechLogoLabel} />*/}
                        {/*    <JobSectionLocation>*/}
                        {/*        Lebanon*/}
                        {/*    </JobSectionLocation>*/}
                        {/*    <JobSectionDesc>*/}
                        {/*        *** PORTFOLIOS ARE REQUIRED FOR THE APPLICATION PROCESS***<br />*/}
                        {/*        Applications without portfolios or a portfolio/website link, will not be considered.*/}
                        {/*    </JobSectionDesc>*/}
                        {/*</JobSection>*/}
                    </JobBigDiv>
                </JobsBody>
            </JobsBackground>
            <StudentBackground ref={internship}>
                <StudentImg />
                <StudentBody>
                    <JobsHeadLogo src={whiteStudent} />
                    <JobsStudentgHead>
                        Student Internships
                    </JobsStudentgHead>
                    <JobDiv>
                        At Potech, we fully embrace the energy and enthusiasm that students bring. We value their fresh perspectives and take pride in contributing to their development through our practical experience and expert skills.
                        <AdvisoryDivLineItemsMobile />

                        If you are a student and you got those vibes, email us at <HrefLink href="mailto:hr@potech.global">hr@potech.global</HrefLink> and we’ll get in touch.
                    </JobDiv>
                </StudentBody>
            </StudentBackground>
            <ResearchBackground ref={research}>
                <ResearchImg />
                <ResearchBody>
                    <JobsHeadLogo src={whitePaperResearch} />
                    <JobsgHead>
                        Advanced Research Support
                    </JobsgHead>
                    <JobDiv>
                        Information & Technology is accompanying – if not leading – the world’s incessant evolution thanks to continuous academic development.
                        <AdvisoryDivLineItemsMobile />
                        For this reason, at Potech, we vigorously put our technical knowledge and professional expertise at the service of students pursuing PhD programs or other advanced studies, supporting those future experts with all the tools needed to propose innovative Information & Technology solutions.
                        <AdvisoryDivLineItemsMobile />
                        We are proud to mention in this context our cooperation agreements with esteemed institutions such as the Lebanese University and Université St Joseph in Lebanon, facilitating the exchange of knowledge and students with both institutions.
                        <AdvisoryDivLineItemsMobile />
                        If you are a student enrolled in an advanced studies program and require our experts’ support for your research, please reach out to us by email at <HrefLink href="mailto:hr@potech.global">hr@potech.global</HrefLink>
                    </JobDiv>
                </ResearchBody>
            </ResearchBackground>
            <FooterDivider />
        </>
    )
}

export default Careers;